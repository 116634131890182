import { toLower, formatDate, dateFormat } from "./helpers"
import { useAuthStore } from "src/stores/auth"

export const setStations = (stations = []) => {
    if (!stations || !stations.length) return []
    return stations.map((st, i) => ({
        ...st,
        operator: st.operator ? st.operator : `Operator ${i + 1}`
    }))
}

export const hasConsecutiveMachineTask = (steps) => {
    const key = 'machine'
    return steps.some((s, i, arr) => i < arr.length - 1 ? toLower(s.action_type) === key && toLower(arr[i + 1]?.action_type) === key : false)
}

export const hasMachineStepAsPredecessor = (steps) => {
    const key = 'machine'
    const machineStepIds = steps.filter((s) => toLower(s.action_type) === key).map(s => s.id)
    return steps.some((s, i) => i > 0 ? toLower(s.action_type) === 'manual' && s.predecessor.some((p) => machineStepIds.includes(p)) : false)
}

export const hasDuplicationActionNames = (actions) => {
    const steps = actions.map(step => step.name.trim());
    if (actions.length !== new Set(steps).size) return true
    else return false;
}

export const getActionTypeOptions = (stepNo) => {
    return [
        { label: 'Manual', value: 'Manual' },
        { label: 'Walk', value: 'Walk' },
        { label: 'Machine', value: 'Machine', disabled: !stepNo ? false : stepNo === 0 },
    ]
}

export const setSwctChartConfig = (lineSteps, state) => {
    const { taktTime, operatorsForResults } = state
    const machineSteps = lineSteps.filter((s) => s.action_type === 'Machine')
    // const machineStepsIds = machineSteps?.map((ms) => ms.id)
    const machineStepsPredecessors = machineSteps?.reduce((res, el) => {
        if (el.predecessor.length)
            res = [...res, el.predecessor.at(-1)]
        return res
    }, [])
    const machineVariable = {
        pointWidth: 3,
        pointPadding: 0,
        color: '#000000',
    }
    let result = []
    let count = 0;
    let lastStepTime = 0

    operatorsForResults.forEach((operatorData, operatorIndex) => {
        lastStepTime = 0
        const operatorStepsIds = operatorData.station_steps?.flat()
        const operatorStationSteps = lineSteps.filter((step) => operatorStepsIds.includes(step.id))
        let cycleTime = operatorStationSteps.reduce((sum, el) => sum += el.computed_time, 0)
        const operatorMachineSteps = machineSteps.filter((ms) => operatorStepsIds.includes(ms.predecessor.at(-1)))
        const highestMachineTime = operatorMachineSteps.length && Math.max(...operatorMachineSteps.map((ms) => Number(ms.computed_time)))
        if (highestMachineTime > cycleTime)
            cycleTime = highestMachineTime

        operatorStationSteps?.forEach((step, index, array) => {
            const predecessorOfMachineStep = machineStepsPredecessors?.some((p) => p === step.id)
            // if (lastStepTime >= taktTime) lastStepTime = 0

            const manualObj = {
                ...step,
                ...setActionTypeTime(step.action_type, step.computed_time),
                operatorIndex,
                y: count,
                x: lastStepTime,
                x2: lastStepTime + step.computed_time,
                pointWidth: 32,
                color: step.action_type === 'Walk' ? { pattern: { path: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11', color: '#baabab', height: 10, width: 10 } } : '#50c2e7',
            }
            result.push(manualObj)

            count = count + 1
            lastStepTime = lastStepTime + step.computed_time

            if (predecessorOfMachineStep) {
                // if (lastStepTime >= taktTime) lastStepTime = 0

                const machineStep = machineSteps.find((ms) => ms.predecessor.includes(step.id))
                const machineTime = machineStep.computed_time

                const machineStepAfterTaktTime = lastStepTime >= taktTime
                const max = lastStepTime + machineTime

                let lastLimit = machineStepAfterTaktTime ? cycleTime : taktTime

                // Machine Time exceeds Takt Time/Cycle Time - Case 4 Commented
                // if (machineTime > lastLimit) {
                    // lastLimit = machineTime
                    // const sumOfPreviousSteps = array.slice(0, index + 1).reduce((sum, el) => sum += el.computed_time, 0)
                    // const sumOfNextSteps = index < array.length - 1 ? array.slice(index + 1, array.length).reduce((sum, el) => sum += el.computed_time, 0) : 0

                    // const waitingTime = machineTime - (sumOfPreviousSteps + sumOfNextSteps)
                    // if (waitingTime > 0) lastStepTime = lastStepTime + waitingTime
                // }
                const machineStartTime = lastStepTime

                // const restartTime = machineStepAfterTaktTime ? max - cycleTime : max - taktTime
                // const prevStepStartTime = manualObj.x
                const obj = {
                    ...machineStep,
                    ...setActionTypeTime(machineStep.action_type, machineTime),
                    ...machineVariable,
                    operatorIndex,
                    y: count,
                    x: machineStartTime,
                    x2: max > lastLimit ? lastLimit : max,
                }

                const restartTime = obj.x2 - obj.x >= 1 ? Math.floor(machineTime) - (Math.floor(obj.x2) - Math.floor(obj.x)) : machineTime

                // Machine Task overlaps Manual Task - case 2
                if(manualObj.x < restartTime){
                    // change manual object x1 and x2 position
                    const addTime = restartTime-manualObj.x
                    manualObj.x+=addTime
                    manualObj.x2+=addTime
                    // change obj1 x1 and x2 position
                    obj.x+=addTime
                    obj.x2+=addTime
                    // add time in last time to move further steps
                    lastStepTime+=addTime
                }

                if (restartTime > 0) {
                    let color = '#000000'
                    // if (restartTime >= prevStepStartTime) color = '#ff0000'
                    const obj2 = {
                        ...obj,
                        x: 0,
                        x2: restartTime,
                        color: color,
                        excess: true
                    }
                    obj['color'] = color
                    if (obj.x2 - obj.x >= 1) result.push({ ...obj }) // x: obj.x == obj2.x2 ? obj.x + 1 : obj.x
                    result.push(obj2)
                } else {
                    result.push(obj)
                }
                count = count + 1
            }
            // if (!predecessorOfMachineStep) lastStepTime = lastStepTime + step.computed_time
            // count = count + 1
        })
    })
    return result
}

const setActionTypeTime = (action_type, time) => {
    const action_time = {
        manual_time: null,
        walk_time: null,
        machine_time: null
    }
    switch (action_type) {
        case 'Walk':
            action_time['walk_time'] = time
            break
        case 'Manual':
            action_time['manual_time'] = time
            break
        case 'Machine':
            action_time['machine_time'] = time
            break
    }
    return { ...action_time }
}

export const getCsvFormattedData = (state) => {
    const { organization } = useAuthStore()
    const selectedBalanceLine = state.historyLineBalance.find((bl) => bl.value === state.selectedHistory)
    const data = {
        name: selectedBalanceLine.label,
        takt_time: state.taktTime,
        creation_date: formatDate(new Date().toISOString(), dateFormat),
        stations: state.lineStationsForResults.map((st, i) => ({
            name: state.unbalanceLineSelected && !state.isDesigningNewLine ? st.name : `Station ${i + 1}`,
            organization: organization,
            steps: st.station_steps,
        })),
        operators: state.operatorsForResults.map((op, i) => ({
            name: state.unbalanceLineSelected && !state.isDesigningNewLine ? op.name : `Operator ${i + 1}`,
            steps: op.station_steps.flat()
        }))
    }
    return data
}