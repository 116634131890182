import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes/index'
import { useAuthStore } from 'src/stores/auth'
import { roles } from 'src/config/roles-config'

// const authStore = useAuthStore()
const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const isTokenSet = authStore.isTokenSet
  const role = authStore.role

  if (isTokenSet && to.path.includes('/reset-password/')) {
    localStorage.clear()
    // store.dispatch('changeRoute', [to.name]);
    next()
  }

  if (to.path === '/') {
    return redirectToAccuratePath(role, next)
  }
  if (to.path.endsWith('station-design')) {
    return next({ name: 'studies' })
  }
  if (to.path.endsWith('line-balancing')) {
    return next({ name: 'Lines' })
  }
  if (to.path.endsWith('ergonomics')) {
    return next({ name: 'Assessments' })
  }
  if (to.path.endsWith('fmea')) {
    return next({ name: 'FMEA Records' })
  }
  // redirect to auth page when user not authorize and try to navigate to dashboard route
  if (!isLoginOrSignup(to) && !isTokenSet) {
    authStore.clearState();
  }

  // redirect to home page when user logged-in and tries to go auth routes
  if (isTokenSet && isLoginOrSignup(to)) {
    return redirectToAccuratePath(role, next);
  }

  next()
})

function redirectToAccuratePath(role, next) {
  switch (role) {
    case roles.admin:
      return next({ name: 'Admin Layout' })
    case roles.user:
    case roles.org_user:
    case roles.support_user:
      return next({ name: 'Home' })

    default:
      return next({ name: 'Login' })
  }
}

function isLoginOrSignup(to) {
  return [
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password'
  ].some((url) => to.path.includes(url))
}

// function isValidRole(role) {
//   if (!Object.values(roles).includes(role)) {
//     // store.dispatch('clearState');
//     return false
//   }
//   return true
// }

export default router
