import { jointsDict, leftRightJointsConfig, riskColorsMap, jointsOptionConfig, loadAnalysisChartConfig } from 'src/config/joints-config'
import { nioshAssessmentResultData, recommendedWeightLimitData, loadAnalysisData } from 'src/config/ergonomicsSeededData'
import { snookResultRecommColumns, loadClassesLabelsMap, loadClassesColorsMap, snookGenderKeys } from 'src/config/niosh-snook-assessment-config'
import { frequency as frequencyOptions, liftLowerPoint, liftLowerPointF, carryPoint, carryPointF, pushPullPoint, pushPullPointF } from 'src/components/User/Ergonomics/ErgonomicAssessment/SnookAssessment/config'
import { assessmentVariableMap } from 'src/stores/ergonomics'
import { getIntervalTime } from './outline'

export const getPostureIndexName = (num) => {
  if (num >= 26) {
    const count = num % 26
    const identifier = num / 26
    return `${String.fromCharCode(65 + count)}${Math.floor(identifier)}`
  } else return String.fromCharCode(65 + num)
}

export const getJsonVariables = (assessmentType, forResult = false) => {
  let variables = []
  const key = Object.keys(assessmentVariableMap).find((key) => key.includes(assessmentType))
  if (!assessmentType || !key) return
  if (!forResult) {
    if (['reba', 'rula'].includes(assessmentType)) variables = ['intervals', 'output_video']
    else if (assessmentType === 'niosh') variables = ['tracking_video', 'NIOSH']
    else if (assessmentType === 'snook table') variables = ['tracking_video', 'SNOOK TABLE']
    else if (assessmentType === 'hand grip & insertion force') variables = ['hand_ergonomics_data']
    // else if (assessmentType === 'hand grip & insertion force result') variables = ['hand_ergonomics_result', 'hand_ergonomics_data']
  } else {
    // Result page
    variables = Object.keys(assessmentVariableMap[key])
    if(assessmentType == 'rula'){
      variables = variables.filter(data => data !== 'REBA')
    }else if(assessmentType == 'reba'){
      variables = variables.filter(data => data !== 'RULA')
    }
  }
  return { key, variables }
}

// Intervals Data Modeling
export const getIntervalsData = (intervals) => {
  if (!intervals || !Object.keys(intervals)?.length) return []
  return Object.entries(intervals)?.map(([key, interval]) => {
    const { postures_at_risk, frame_interval, ...obj } = interval
    return {
      ...obj,
      ...postures_at_risk,
      frame_interval: Array.isArray(frame_interval) ? {
        start: frame_interval[0],
        end: frame_interval[frame_interval.length - 1]
      } : frame_interval,
      key: key
    }
  })
}

export const mapIntervalsDataFromHandErgonomicsData = (handErgonomicsData) => {
  return handErgonomicsData.map((data, i) => ({ key: i, frame_interval: { start: data.segment_start, end: data.segment_end } }))
}

export function getLimbName(attr) {
  const key = typeof attr == 'string' ? Object.keys(jointsDict)?.find((joint) => attr.includes(joint)) : ''
  return key ? jointsDict[key] : '-'
}

export const scoreKeys = {
  reba: 'final_reba_score',
  rula: 'final_rula_score',
};

export const getREBAScoreSeverety = (score) => {
  switch (true) {
    case score >= 1 && score <= 3:
      return 'Low';
    case score >= 4 && score <= 7:
      return 'Medium';
    case score >= 8 && score <= 10:
      return 'High';
    case score >= 11:
      return 'Very High';
    default:
      return '';
  }
};

export const getRULAScoreSeverety = (score) => {
  switch (true) {
    case score >= 1 && score <= 2:
      return 'Low';
    case score >= 3 && score <= 4:
      return 'Medium';
    case score >= 5:
      return 'High';
    default:
      return '';
  }
};

// Posture Analysis
export function getJointScoreSeverityValue(frameData, scoreType = 'reba') {
  let obj = {}
  const scoreThresholdKey = scoreType + '_Thresholds';
  const postureValues = Object.values(leftRightJointsConfig)
  jointsOptionConfig.forEach((joint) => {
    const limbkey = joint.value
    const posture = postureValues.find((pos) => pos.scoreAttribute === limbkey)
    const thresholdKeys = Object.keys(posture[scoreThresholdKey])
    const score = frameData[limbkey]
    const threshold = thresholdKeys.includes(score) ? score : thresholdKeys.reduce((closest, current) =>
      Math.abs(current - score) < Math.abs(closest - score) ? current : closest
    );
    obj[limbkey] = posture[scoreThresholdKey][threshold]
  })
  // assessment Score (not needed though)
  const scoreKey = scoreKeys[scoreType]
  obj[scoreKey] = scoreType === 'reba' ? getREBAScoreSeverety(frameData[scoreKey]) : scoreType === 'rula' && getRULAScoreSeverety(frameData[scoreKey])
  return obj;
}

export const getLimbPriority = (limbData) => {
  let limbPriorities = [[], [], []];
  const priorityVal = { high: 0, medium: 1, low: 2 }

  for (const key in limbData) {
    const limb = limbData[key];
    let priority = '';
    if (limb.high > 0) priority = 'high';
    else if (limb.medium > 0) priority = 'medium';
    else priority = 'low';
    limbPriorities[priorityVal[priority]].push({ key, priority, value: limb[priority] });
  }
  limbPriorities = limbPriorities.flat()
  // limbPriorities.sort(comparePriority)

  let priorityObj = {};
  let pAssign = 0;
  for (let i = 0; i < limbPriorities.length; i++) {
    const curr = limbPriorities[i]

    if (priorityObj.hasOwnProperty(getLimbName(curr.key))) continue
    const oldLength = Object.keys(priorityObj).length
    const samePriorityValues = limbPriorities.filter((limb) => limb.priority === curr.priority)
    samePriorityValues.length > 1 && samePriorityValues.sort((a, b) => b.value - a.value)

    samePriorityValues.forEach(({ key, value }, index, prev) => {
      pAssign = prev[index - 1] && prev[index - 1]?.value === value ? pAssign : ++pAssign
      priorityObj[getLimbName(key)] = pAssign
    })
  }
  console.log('priority obj ->', priorityObj)
  return priorityObj
}

export const getIntervalVideoTime = (fps, interval) => {
  // start & end frame of interval
  const iStart = interval.start;
  const iEnd = interval.end;

  let time = '';
  // calculate second a/c to fps
  const start = iStart / fps;
  const end = iEnd / fps;

  const formattedStart = getIntervalTime(start * 1000);
  const formattedEnd = getIntervalTime(end * 1000);

  // if interval is greater than equal to 1 second
  if (iEnd - iStart < fps) {
    time = formattedStart;
  } else if (iEnd - iStart >= fps) {
    time = `(${formattedStart} - ${formattedEnd})`;
  }
  return time;
};

// NIOSH / SNOOK
export const nioshResultTableData = (json) => {
  if (!json || !Object.keys(json)?.length) return
  let origin = ''
  let dest = ''
  return nioshAssessmentResultData.map((obj) => {
    origin = obj.key + '_origin'
    dest = obj.key + '_dest'
    return {
      ...obj,
      origin: json[origin],
      dest: json[dest]
    }
  })
}

export const snookResultTableData = (inputs, json, snookType, genderType = 'male') => {
  const preprocessKeys = {
    pushing: {
      'frequency': (value, arr) => arr.find((fr) => fr.value === value)?.label,
      'vertical_location': (value) => {
        if(genderType === 'female') {
          return pushPullPointF.find((pp) => pp.value == value)?.label
        } else {
          return pushPullPoint.find((pp) => pp.value == value)?.label
        }
      }
    },
    pulling: {
      'frequency': (value, arr) => arr.find((fr) => fr.value === value)?.label,
      'vertical_location': (value) => {
        if(genderType === 'female') {
          return pushPullPointF.find((pp) => pp.value == value)?.label
        } else {
          return pushPullPoint.find((pp) => pp.value == value)?.label
        }
      }
    },
    lifting: {
      'frequency': (value, arr) => arr.find((fr) => fr.value === value)?.label,
      'vertical_location': (value) => {
        if(genderType === 'female') {
          return liftLowerPointF.find((lp) => lp.value == value)?.label
        } else {
          return liftLowerPoint.find((lp) => lp.value == value)?.label
        }
      }
    },
    carrying: {
      'frequency': (value, arr) => arr.find((fr) => fr.value === value)?.label,
      'vertical_location': (value) => {
        if(genderType === 'female') {
          return carryPointF.find((cp) => cp.value == value)?.label
        } else {
          return carryPoint.find((cp) => cp.value == value)?.label
        }
      }
    },
  }
  return inputs?.map((o) => {
    let value = ''
    const preprocessKey = preprocessKeys[snookType]
    value = json.hasOwnProperty(o.key) ? json[o.key] : json[0]?.hasOwnProperty(o.key) ? json[0][o.key] : null
    if (preprocessKey && Object.keys(preprocessKey)?.includes(o.key)) {
      value = preprocessKey[o.key](value, o.options)
    }

    return {
      ...o,
      value: value
    }

  })
}

export const nioshWeightLimitData = (json) => {
  if (!json || !Object.keys(json)?.length) return
  let origin = ''
  let dest = ''
  return recommendedWeightLimitData.map((obj) => {
    let children = obj.children
    children = children.map((child) => {
      origin = child.key + '_origin'
      dest = child.key + '_dest'
      return {
        ...child,
        origin: json[origin] ? json[origin].toFixed(2) : '',
        dest: json[dest] ? json[dest].toFixed(2) : ''
      }
    })
    return { ...obj, children: [...children] }
  })
}

export const snookResultRecommData = (json, genderType, snookType = '') => {
  return snookResultRecommColumns.map((c) => {
    let children = c.children
    children = children.map((ch) => {
      let value = json[ch.key]
      let obj = {
        ...ch,
        // label: ch.key === 'gender_population' ? ch.label.replace('@gender', genderType) : ch.label,
      }

      if (ch.key === 'risk_index') {
        value = (json['weight'] / json['design_goal'])
        if (['pushing', 'pulling'].includes(snookType)) {
          obj['firstValue'] = (json['initial_force'] / json['initial_design_goal'])?.toFixed(2);
          obj['secondValue'] = (json['sustained_force'] / json['sustained_design_goal'])?.toFixed(2);
        } else obj['firstValue'] = value ? value.toFixed(2) : ''
      }
      // else if (ch.key === 'gender_population') {
      //   const genderKey = snookGenderKeys[snookType]
      //   if (Array.isArray(genderKey)) {
      //     obj['firstValue'] = json[genderKey[0]]
      //     obj['secondValue'] = json[genderKey[1]]
      //   } else obj['firstValue'] = json[genderKey]
      // }
      else {
        if (['pushing', 'pulling'].includes(snookType)) {
          obj['firstValue'] = json[ch.first_value_key]
          obj['secondValue'] = json[ch.second_value_key]
        } else obj['firstValue'] = value ? value : ''
      }
      return obj
    })
    return { ...c, children: [...children] }
  })
}

export const getRiskIndexColorValue = (value) => {
  value = Number(value)
  return value > 3 ? riskColorsMap['High'] :
    value < 1 ? riskColorsMap['Low'] : (value >= 1 && value <= 3) ?
      riskColorsMap['Medium'] : 'transparent'
}

export const getLoadActivityFrequency = (jobPercent) => {
  jobPercent = Number(jobPercent);
  if (jobPercent < 1) return 'Never';
  else if (jobPercent >= 1 && jobPercent <= 31) return 'Ocassionally';
  else if (jobPercent >= 34 && jobPercent <= 66) return 'Frequently';
  else if (jobPercent >= 67 && jobPercent <= 100) return 'Constantly';
};

// export const getSnookLiftVerticalLocation = (verticalLocation) => {
//   verticalLocation = Number(verticalLocation);
//   if (verticalLocation <= 28) return 'Floor to Knuckel';
//   else if (verticalLocation >= 29  && verticalLocation <= 54) return 'Knuckle to Shoulder';
//   else if (verticalLocation >= 55) return 'Above Shoulder';
//};

// export const getSnookCarryCarryPoint = (carryPoint) => {
//   carryPoint = Number(carryPoint);
//   if (carryPoint === 43) return 'Waist height (elbows bent)';
//   else if (carryPoint === 33) return 'Below Waist Height (elbows straight)';
// };

export const getSnookPushVerticalLocation = (verticalLocation) => {
  verticalLocation = Number(verticalLocation);
  if (verticalLocation <= 24) return 'Low (hands about 24")';
  else if (verticalLocation >= 25 && verticalLocation <= 36) return 'Middle (hands about 36")';
  else if (verticalLocation >= 37 && verticalLocation <= 55) return 'High (hands about 55")';
};


export const getLoadAnalysisTableData = (json) => {
  if (!json || !Object.keys(json)?.length) return
  const tableData = Object.entries(loadAnalysisData).map(
    ([loadClass, percentage]) => ({
      weight_class: loadClassesLabelsMap[loadClass],
      original_class: loadClass,
      job_percent: json[loadClass],
      frequency: getLoadActivityFrequency(json[loadClass])
    })
  )
  return tableData
}

export const getLoadAnalysisChartData = (json) => {
  if (!json || !Object.keys(json)?.length) return loadAnalysisChartConfig
  const labels = Object.keys(json)

  // const data = Object.values(json)
  const data = Object.keys(loadClassesLabelsMap)

  // var uniqueClasses = [...new Set(Object.values(json))]
  let classToScoreObj = {}
  let scoreToClassObj = {}

  data.forEach((c, i) => {
    classToScoreObj[c] = i + 1
    scoreToClassObj[i + 1] = loadClassesLabelsMap[c].split('(')[0]
  })

  const config = {
    ...loadAnalysisChartConfig,
    data: Object.values(json).map((category) => ({
      y: classToScoreObj[category],
      color: loadClassesColorsMap[category]
    })),
    labels: labels,
    yLabels: data.map((_, i) => i),
    classesScore: scoreToClassObj
  }
  return config
}