import DefineCycles from './components/DefineCycles.vue'
import DivideSegments from './components/DivideSegments.vue'
import DefineSteps from './components/DefineSteps.vue'
import TimeMotionStudyResult from './components/TimeMotionStudyResult.vue'
import MultipleRegions from './components/MultipleRegions.vue'
import ImprovementQuestions from './components/ImprovementQuestions.vue'
import WorkstationAudit from './components/WorkstationAudit.vue'
import ViewResult from './components/ViewResult.vue'
import KaizenNotes from './components/KaizenNotes.vue'

// key are same as the name in the route config of new study
export const steps = [
  { title: 'Cycles', key: 'cycles', component: DefineCycles },
  { title: 'Divide', key: 'divide', component: DivideSegments },
  { title: 'Steps', key: 'steps', component: DefineSteps },
  { title: 'Time Study', key: 'timeStudy', component: TimeMotionStudyResult },
  { title: 'Outline', key: 'outline', component: MultipleRegions },
  { title: 'Improvements', key: 'improvements', component: ImprovementQuestions },
  { title: 'Audit', key: 'audit', component: WorkstationAudit },
  { title: 'Suggestions', key: 'suggestions', component: ViewResult },
  { title: 'Kaizen Notes', key: 'notes', component: KaizenNotes }
]

export const videoRecordOptions = {
  controls: true,
  bigPlayButton: false,
  width: 520,
  height: 280,
  fluid: false,
  controlBar: {
    volumePanel: false,
    deviceButton: false,
    recordToggle: false,
    pipToggle: false
  },
  plugins: {
    record: {
      pip: false,
      audio: false,
      maxLength: 60 * 23 * 60,
      debug: true,
      convertEngine: 'ts-ebml',
      videoMimeType: 'video/webm;codecs=h264',
      videoFrameRate: 30,
      video: {
        minWidth: 640,
        minHeight: 480
      },
      frameWidth: 640,
      frameHeight: 480
    }
  }
}

export const cyclesMenuItem = {
  remove: 'Remove Cycle',
  editStart: 'Edit Cycle Start',
  editEnd: 'Edit Cycle End',
  rename: 'Rename Cycle'
}

export const stepsMenuItem = {
  add: 'Add Step',
  remove: 'Remove Step',
  editStart: 'Edit Step Start',
  editEnd: 'Edit Step End'
}

export const colors = {
  0: '#34568B',
  1: '#009B77',
  2: '#EFC050',
  3: '#88B04B',
  4: '#F7CAC9',
  5: '#92A8D1',
  6: '#955251',
  7: '#B565A7',
  8: '#FF6F61',
  9: '#DD4124',
  10: '#D65076',
  11: '#45B8AC',
  12: '#6B5B95'
}
export const stepsResultAttribute = [
  {
    label: 'Steps detected',
    name: 'steps_detected'
  },
  {
    label: 'Fastest step duration',
    name: 'fastest_step_duration'
  },
  {
    label: 'Longest step duration',
    name: 'longest_step_duration'
  },
  {
    label: 'Average step duration',
    name: 'average_step_duration'
  },
  {
    label: 'Non-value add steps identified',
    name: 'non_value_add_steps'
  }
]

export const chartsValues = [
  {
    label: 'Total Cycle Time',
    name: 'total_cycle_time'
  },
  {
    label: 'Total Value add time',
    name: 'total_value_add_time'
  },
  {
    label: 'VA time',
    name: 'total_va_time'
  }
]

export const processStatus = {
  InProgress: 'In Progress',
  Finished: 'Finished',
  InQueue: 'In Queue',
  Failed: 'Failed'
}

export const InProgressStatus = [processStatus['InProgress'], processStatus['In Queue']]

export const preventCallStatus = [processStatus['InProgress'], processStatus['Finished']]

export const valueAssessment = {
  VA: 'Value-add',
  NVA: 'Non value-add',
  NNVA: 'Necessary non value-add'
}

export const VA_Keys = {
  "Value-add": 'VA',
  "Non value-add": 'NVA',
  "Necessary non value-add": 'NNVA'
}

export const studiesColumn = [
  {
    title: '#',
    dataIndex: 'id',
    align: 'center',
    width: '5%',
    // sorter:true
  },
  {
    title: 'Thumbnail',
    dataIndex: 'icon',
    key: 'icon',
    align: 'center',
    width: '15%',
  },
  // {
  //   title: 'Process Name',
  //   dataIndex: 'study_name',
  //   key: 'study_name',
  //   sorter: true,
  //   align:'center'
  // },
  {
    title: 'Station Name',
    dataIndex: 'station_id',
    key: 'station_id',
    align: 'left',
    width: '15%'
  },
  {
    title: 'Product',
    key: 'part_no',
    dataIndex: 'part_no',
    align: 'center',
    width: '10%',
  },
  {
    title: 'Step Count',
    key: 'no_of_steps',
    dataIndex: 'no_of_steps',
    align: 'center',
    width: '7%'
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Published Date',
    dataIndex: 'time_created',
    key: 'time_created',
    align: 'center',
    sorter: true
  },
  {
    title: 'Actions',
    key: 'action',
    dataIndex: 'action',
    width: '12%',
    align: 'center'
  }

]

export const stationOutlineGuidelines = {
  'Pre-Requisite: ': [
    'To proceed, Select the required number of Work Areas from the provided dropdown menu.',
    'Afterward, you will see the table to mark the Assembly area and operator for the selected number.'
  ],
  'Assembly Area: ': [
    'Click on the Mark Area button for any assembly area in the table and mark the outline of the Assembly area.',
    'Start marking the assembly area from the anticlock-wise direction.',
    'Adjust corners for proper assembly area coverage if necessary.'
  ],
  'Locate Operator: ': [
    'Click on the Locate operator button for any assembly area in the table and mark the assembler in the frame.',
    'To locate the assembler across various work regions, utilize the slider to approximate their location.',
    'Mark the Operator in the center of the station for proper ergonomic regions coverage.'
  ],
  // 'Line Distance: ': [
  //   'Hover over any boundary line of the first marked assembly area and Click on it.',
  //   'The blue line is suppose to represents the actual dimension of the working table.',
  //   'Enter the Line measurement value of any side in inches.'
  // ]
}

export const cellOutlineGuidelines = {
  'Pre-Requisite: ': [
    'To proceed, Select the required number of Work Regions from the provided dropdown menu.',
    'Afterward, you will see the table to mark the regions and operator for the selected number.'
  ],
  'Work Regions: ': [
    'Click on the Mark region button for any region in the table and mark the outline of the region.',
    'Start marking the region from the anticlock-wise direction.',
    'Adjust corners for proper region coverage if necessary.'
  ],
}

export const workAuditCategoryLabels = {
  'FACILITY_RELATED_QUESTION': 'Facility',
  'OPERATORS_ADHERENCE_TO_STANDARD_WORK': 'SWI Adherence',
  'WORKPLACE_DESIGN': 'Workplace Design',
  'MANUFACTURING_WASTES': 'Manufacturing Waste'
}

export const stepsColumn = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    width: '7%',
    align: 'center'
  },
  {
    title: 'Step Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Time (s)',
    dataIndex: 'avg_time',
    key: 'avg_time',
    align: 'center'
  },
  {
    title: 'Std. Deviation',
    key: 'step_std',
    dataIndex: 'step_std',
    align: 'center',
  },
  {
    title: 'Value Type',
    key: 'assessment',
    dataIndex: 'assessment',
    align: 'center'
  },
  {
    title: 'Work Elements',
    key: 'actions',
    dataIndex: 'actions',
    align: 'center',
    width: '25%'
  },
  {
    title: 'Video',
    key: 'segment_video',
    dataIndex: 'segment_video',
    width: '10%',
    align: 'center'
  }

]

export const statusColorMap = {
  Completed: 'success',
  Failed: 'error',
  Processing: 'processing',
  Pending: 'default'
}

export const sortElements =
{
  'Reach and Move': 1,
  'Grasp': 3,
  'Release Load': 4,// Release
  'Pre-Position': 5,
  'Use': 6,
  'Assemble': 7,
  'Disassemble': 8,
  'Search': 9,
  'Select': 10,
  'Position/Orient': 11, //Position
  'Inspect': 12,
  'Plan': 13,
  'Unavoidable Delay': 14,
  'Avoidable Delay': 15,
  'Hold': 16,
  'Rest to overcome fatigue': 17,
  'Walk': 18,
  'Material Movement': 19,
  'Background': 20,//
  'No Human': 21,//
}