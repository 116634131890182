<template>
  <a-modal
    :open="showVideo"
    :title="null"
    :footer="null"
    destroy-on-close
    @cancel="showVideo = false"
  >
    <div class="w-100 pt-4">
      <div class="tableVideoWrapper">
        <SegmentVideo
          v-for="(cycle, index) in selectedStepCycles"
          :key="cycle.id"
          :video-url="!isDemoOngoing ? cycle.url : stationDesignDummyObject.url"
          :segment-play="segmentPlay"
          :duration-time="segmentPlay.duration"
          :frame-per-sec="cycle.videoFPS"
          :styles="{ maxHeight: '480px', objectFit: 'cover' }"
          :wrapperStyles="{
            display: currCycle === index ? 'flex !important' : 'none !important'
          }"
        />
      </div>

      <div class="w-100 d-flex justify-content-center" v-if="segmentPlay">
        <span
          style="margin-left: 36px; gap: 10px"
          class="d-flex align-items-center justify-content-center"
        >
          <LeftOutlined
            class="cycle-nav-icon"
            :class="{ disabled: currCycle <= 0 }"
            @click="handleCycleChange(-1)"
          />
          <span style="font-size: 18px">
            {{ currCycle + 1 }} / {{ selectedStepCycles?.length }}
          </span>
          <RightOutlined
            :class="{ disabled: currCycle === selectedStepCycles?.length - 1 }"
            class="cycle-nav-icon"
            @click="handleCycleChange(1)"
          />
          <sub v-if="segmentPlay?.is_master" style="font-size: 14px">(Master)</sub>
        </span>
      </div>
    </div>
  </a-modal>

  <a-table
    id="steps-result-table"
    :bordered="true"
    :dataSource="stepsData"
    :columns="stepsColumn"
    size="small"
    :pagination="false"
  >
    <template #title>
      <a-typography-title class="m-0" :level="5"> Step List </a-typography-title>
    </template>

    <template #bodyCell="{ record, column, text, index }">
      <span v-if="column.dataIndex === 'index'">{{ index + 1 }} </span>
      <span v-if="column.dataIndex === 'avg_time'">{{ text }} s</span>
      <span v-if="column.dataIndex === 'step_std'"> {{ text.toFixed(2) }}s </span>
      <span v-if="column.dataIndex === 'assessment'">
        {{ getValueType(record.assessment) }}
      </span>
      <span
        v-if="column.dataIndex === 'actions'"
        class="w-100 d-flex flex-wrap justify-content-center"
        >{{ record.actions.length ? record.actions?.join(', ') : '-' }}</span
      >
      <span v-if="column.dataIndex === 'segment_video'" class="d-flex justify-content-center">
        <a-button
          class="d-flex align-items-center justify-content-center"
          shape="circle"
          @click="handleChangeStep(record.step_index)"
        >
          <template #icon>
            <CaretRightOutlined style="font-size: 20px" />
          </template>
        </a-button>
      </span>
    </template>
  </a-table>
</template>
<script>
import { stepsColumn, VA_Keys } from '../config'
import { mapState } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useSegmentationStore } from 'src/stores/segmentation'
import { CaretRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import SegmentVideo from './SegmentVideo.vue'
import { useUserStore } from 'src/stores/user'

export default {
  setup: () => ({
    stepsColumn,
    VA_Keys
  }),
  components: { CaretRightOutlined, LeftOutlined, RightOutlined, SegmentVideo },
  data() {
    return {
      showVideo: false,
      selectedStepCycles: [],
      currStepIndex: 0,
      currCycle: 0,
      segmentPlay: {
        start: 0,
        end: 0
      }
    }
  },
  computed: {
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'masterCycleVideoId',
      'studyFilesObject',
      'newStudyId'
    ]),
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useSegmentationStore, [
      'segmentationDatForSuggestions',
      'stepToCyclesSegmentation'
    ]),

    stepsData() {
      if (this.isDemoOngoing) {
        return this.stationDesignDummyObject.segmentationDatForSuggestions
      }
      return (
        this.segmentationDatForSuggestions?.map((step, i) => ({
          ...step,
          name: step.name ? step.name : `Step ${i + 1}`
        })) || []
      )
    }
  },
  methods: {
    getValueType(assesment) {
      return this.VA_Keys[assesment]
    },

    setSelectedStepCycles(currStepIndex) {
      this.selectedStepCycles = this.stepToCyclesSegmentation[currStepIndex] || []
    },

    handleCycleChange(count = 0) {
      this.currCycle = this.currCycle + count
      const segment = this.selectedStepCycles[this.currCycle]
      if (!segment) return
      this.handlePlaySegmentVideo(segment)
    },

    handlePlaySegmentVideo(segment) {
      const { segment_start, segment_end, work_cycle, videoFPS } = segment
      this.segmentPlay = {
        start: segment_start / videoFPS,
        end: segment_end / videoFPS,
        duration: Number(((segment_end - segment_start) / videoFPS).toFixed(2)),
        is_master: work_cycle?.is_master
      }
    },

    handleChangeStep(index) {
      this.currStepIndex = index
      this.currCycle = 0
      this.setSelectedStepCycles(index)
      this.handleCycleChange()
      this.showVideo = true
    }
  }
}
</script>
<style>
#steps-result-table > .ant-table-title {
  background: #fafafa !important;
}
#steps-result-table > .ant-table-title .ant-space .ant-space-item .ant-typography {
  color: black !important;
}

.tableVideoWrapper {
  position: relative;
  overflow: hidden;
  /* width: 400px; */
}
</style>
