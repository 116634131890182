<template>
  <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
export default {
  props: ['title', 'data'],
  mounted() {
    this.setChartOptions()
  },
  data() {
    return {
      chartOptions: null
    }
  },
  watch: {
    data() {
      this.setChartOptions()
    }
  },
  computed: {
    seriesData() {
      if (!this.data.length) return []
      const seriesData = this.data.filter((s) => s.data[0] !== 0)
      return seriesData
    }
  },
  methods: {
    setChartOptions() {
      const self = this
      this.chartOptions = {
        chart: {
          type: 'column',
        },

        credits: {
          enabled: false
        },

        title: {
          text: ''
        },

        xAxis: {
          type: 'category',
          title: {
            text: 'Station'
          },
          labels: {
            // rotation: -45,
            style: {
              display: 'none'
              // fontSize: '12px',
              // fontFamily: 'Verdana, sans-serif',
              // width: '100%'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Time in seconds'
          },
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return `<span>${this.series.name}&nbsp;: <b>${this.point.y}s</b> </span><br/>`
          }
        },
        plotOptions: {
          column: {
            // pointWidth: 20,
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          }
        },
        series: self.seriesData,
        exporting: {
          enabled: false
        }
      }
    }
  }
}
</script>
<style></style>
