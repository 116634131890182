import ApiHelper from './index'

const uploadFiles = async (payload, stateObj = {}, fileName = '') => {
  return await ApiHelper.upload('file_management/files', payload, stateObj, fileName)
}

const getFiles = async (params) => {
  return await ApiHelper.get('file_management/files', params)
}

const getInProcessFiles = async (params = {}) => {
  return await ApiHelper.get(`file_management/files`, params)
}

const getFileOptions = async () => {
  return await ApiHelper.get('file_management/file_options')
}

const retrieveFile = async (id) => {
  return await ApiHelper.get(`file_management/file/${id}`)
}

const deleteFile = async (id) => {
  return await ApiHelper.remove(`file_management/file/${id}`)
}

const updateFile = async (id, payload) => {
  return await ApiHelper.patch(`file_management/file/${id}`, payload)
}

const getAllTags = async () => {
  return await ApiHelper.get('file_management/tags/')
}

const deleteTag = async (id) => {
  return await ApiHelper.remove(`file_management/tag/${id}`)
}

const addTag = async (payload) => {
  return await ApiHelper.post('file_management/tags/', payload)
}

const associateTags = async (id, payload) => {
  return await ApiHelper.patch(`file_management/patch_tag_to_file/${id}`, payload)
}

const createVideoFileRecord = async (payload) => {
  return await ApiHelper.post(`file_management/create_file_record/`, payload)
}

const createFileV1 = async (payload) => {
  return await ApiHelper.post(`file_management/v1/file/`, payload)
}

const inspectFile = async (payload) => {
  return await ApiHelper.post(`file_management/v1/file_inspect/`, payload)
}

export default {
  uploadFiles,
  retrieveFile,
  getFiles,
  getFileOptions,
  deleteFile,
  updateFile,
  getAllTags,
  addTag,
  associateTags,
  createVideoFileRecord,
  getInProcessFiles,
  deleteTag,
  createFileV1,
  inspectFile
}
