export const lineBalancingDummyData = {
    newLineSteps: [
      {
        id: 13934,
        name: 'place ventilated work bench',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 10,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [],
        computed_time: 10,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13935,
        name: 'install motor and pumps',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 12,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13934],
        computed_time: 12,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13936,
        name: 'drill holes for water pipes',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 17,
        action_type: 'Machine',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13935],
        computed_time: 17,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13937,
        name: 'Install Water Filtration System',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 30,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13935],
        computed_time: 30,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13938,
        name: 'Place the Coffee Maker',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 10,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13937],
        computed_time: 10,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13939,
        name: 'Connect to Water Supply:',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 15,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13938],
        computed_time: 15,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13940,
        name: 'Connect to Power',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 20,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13939],
        computed_time: 20,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      },
      {
        id: 13941,
        name: 'close',
        segment_start: null,
        segment_end: null,
        step_index: null,
        stopclock_time: 4,
        action_type: 'Manual',
        value_type: 'Value-add',
        value_type_by_user: false,
        work_cycle: 510,
        parent_step: null,
        predecessor: [13940],
        computed_time: 4,
        station: 1368,
        piece_per_cycle: 1,
        operator: 'Operator 1'
      }
    ],
    stationSeries: [
      {
        name: 'Station 1',
        data: [101],
        color: '#FF726F',
        utilizationPercentage: '336.67',
        valuePercentage: '100.00'
      }
    ],
    seriesData: [
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: true,
        data: [
          {
            id: 13941,
            station: 1368,
            y: 4,
            action: 'close',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      },
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: false,
        data: [
          {
            id: 13940,
            station: 1368,
            y: 20,
            action: 'Connect to Power',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      },
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: false,
        data: [
          {
            id: 13939,
            station: 1368,
            y: 15,
            action: 'Connect to Water Supply:',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      },
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: false,
        data: [
          {
            id: 13938,
            station: 1368,
            y: 10,
            action: 'Place the Coffee Maker',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      },
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: false,
        data: [
          {
            id: 13937,
            station: 1368,
            y: 30,
            action: 'Install Water Filtration System',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      },
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: false,
        data: [
          {
            id: 13935,
            station: 1368,
            y: 12,
            action: 'install motor and pumps',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      },
      {
        name: 'Value-add',
        color: '#ADD8E6',
        showInLegend: false,
        data: [
          {
            id: 13934,
            station: 1368,
            y: 10,
            action: 'place ventilated work bench',
            value_type: 'Value-add',
            color: '#ADD8E6',
            action_type: 'Manual'
          }
        ]
      }
    ],
    theoreticalBalanceValues: {
      'Takt time': {
        value: '70',
        unit: 's'
      },
      'No of Operators': {
        value: '',
        unit: ''
      },
      'LBR %': {
        value: '-',
        unit: ''
      },
      'Target Volume/hour': {
        value: '',
        unit: ''
      }
    },
    recommendations: [
      {
        takt_time: '30',
        volume: 120,
        LBR: 0.842,
        operators: 4,
        stations: [
          {
            name: 'Station 1',
            balance_copies: 1,
            station_steps: [13934, 13935]
          },
          {
            name: 'Station 2',
            balance_copies: 1,
            station_steps: [13937]
          },
          {
            name: 'Station 3',
            balance_copies: 1,
            station_steps: [13938, 13939]
          },
          {
            name: 'Station 4',
            balance_copies: 1,
            station_steps: [13940, 13941]
          }
        ],
        Machines: [
          {
            name: 'Machine 1',
            balance_copies: 1,
            station_steps: [13936]
          }
        ],
        suggestion: 'Requested takt time',
        id: 0,
        stations_len: 4
      },
      {
        takt_time: '31',
        volume: 117,
        LBR: 0.815,
        operators: 4,
        stations: [
          {
            name: 'Station 1',
            balance_copies: 1,
            station_steps: [13934, 13935]
          },
          {
            name: 'Station 2',
            balance_copies: 1,
            station_steps: [13937]
          },
          {
            name: 'Station 3',
            balance_copies: 1,
            station_steps: [13938, 13939]
          },
          {
            name: 'Station 4',
            balance_copies: 1,
            station_steps: [13940, 13941]
          }
        ],
        Machines: [
          {
            name: 'Machine 1',
            balance_copies: 1,
            station_steps: [13936]
          }
        ],
        suggestion: 'Requested takt time',
        id: 1,
        stations_len: 4
      },
      {
        takt_time: '40',
        volume: 90,
        LBR: 0.842,
        operators: 3,
        stations: [
          {
            name: 'Station 1',
            balance_copies: 1,
            station_steps: [13934, 13935]
          },
          {
            name: 'Station 2',
            balance_copies: 1,
            station_steps: [13937, 13938]
          },
          {
            name: 'Station 3',
            balance_copies: 1,
            station_steps: [13939, 13940, 13941]
          }
        ],
        Machines: [
          {
            name: 'Machine 1',
            balance_copies: 1,
            station_steps: [13936]
          }
        ],
        suggestion: 'Saves 1 operators cost per line but adds-up 10 extra seconds of takt time.',
        id: 2,
        stations_len: 3
      }
    ],
    stepIdToIndexMap: {
      12891: 1,
      12892: 2,
      12893: 3,
      12894: 4,
      12895: 5,
      12896: 6,
      12897: 7,
      13233: 8
    },
    totalManualWorkContent: 180.0,
    totalWorkContent: 180.0,
    taktTime: 30,
    lineHeading: 'WH020 on Sensor Line 1'
  }