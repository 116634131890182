import { roles } from 'src/config/roles-config.js'

export const isUserAssignedToOrg = async (data) => {
  const { is_admin, Organization, role } = data
  return !Organization && !is_admin && role !== roles.labeler
}
export const validUserName = async (_, name) => {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return Promise.resolve();
  }
  const fieldName = _.field.charAt(0).toUpperCase() + _.field.slice(1);
  if (name === '') return Promise.reject(`${fieldName} is required!`);
  return Promise.reject(
    'Invalid Name. Valid name only contain letters, dashes (-) and spaces'
  );
};