export const roles = {
  admin: 'admin',
  user: 'org_admin',
  org_user: 'org_user',
  // labeler: 'labeler',
  // analytics_user: 'analytics_user',
  // workstation_user: 'workstation_user',
  // support_user: 'support_user',
  // doc_user: 'doc_user'
}

export const roleLabels = {
  [roles.admin]: 'Super Admin',
  [roles.user]: 'Organization Admin',
  [roles.org_user]: 'Organization User',
  // [roles.labeler]: 'Labeler',
  // [roles.analytics_user]: 'Analytics User',
  // [roles.workstation_user]: 'Workstation User',
  // [roles.support_user]: 'Support User',
  // [roles.doc_user]: 'Docs User'
}

export const usersTableColumn = [
  {
    title: 'First Name',
    dataIndex: 'username',
    align: 'center',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    align: 'center',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    width: '10%',
  }
]