<template>
  <div
    class="position-relative d-flex flex-column align-items-center"
    :style="{ ...wrapperStyles }"
  >
    <div class="video-left">
      <span
        v-if="count && segmentId"
        class="segment-count"
        :class="{
          'selected-segment': !isUpdating && selected,
          'disabled-count': isUpdating
        }"
        @click="handleSelectCycle"
      >
        <a-spin v-if="selected && isUpdating" size="small" />
        <span v-else> {{ count }}</span>
      </span>
      <span class="videoName" v-if="segmentPlay.name">{{ segmentPlay.name }}</span>
    </div>
    <a-button
      v-if="count && segmentId"
      shape="circle"
      danger
      class="delete-seg d-flex justify-content-center align-items-center"
      @click="handleDeleteCycle"
    >
      <template #icon>
        <a-spin v-if="deleteCycleId === segmentId" size="small" />
        <DeleteOutlined v-else style="color: red" />
      </template>
    </a-button>

    <video
      ref="video"
      muted
      disablePictureInPicture
      class="preview-segment-video"
      :style="{ ...styles }"
      crossorigin="anonymous"
      @loadedmetadata="onVideoMetaLoaded"
      @pause="isPlayingSegment = false"
      @playing="isPlayingSegment = true"
      :src="videoUrl"
      :key="videoUrl"
    ></video>

    <a-flex class="w-100" justify="space-between">
      <a-flex style="width: 50px">
        {{ getIntervalTime((segmentPlay.start + progress) * 1000) }}
      </a-flex>
      <a-flex class="player-icons" gap="small">
        <step-backward-outlined @click="handleMoveBackward" />
        <pause-outlined v-if="isPlayingSegment" @click="pauseSegment" />
        <caret-right-outlined v-else @click="playSegment" />
        <step-forward-outlined @click="handleMoveForward" />
      </a-flex>
      <a-flex style="width: 50px" justify="flex-end">{{
        segmentPlay.end ? getIntervalTime(segmentPlay.end * 1000) : 0
      }}</a-flex>
    </a-flex>

    <div style="height: 5px; background: lightgray; border-radius: 5px" class="w-100 d-flex">
      <div
        class="h-100 position-relative"
        style="background: rgb(24, 144, 255); border-radius: 5px"
        :style="{ width: (progress / (segmentPlay.end - segmentPlay.start)) * 100 + '%' }"
      >
        <!-- <span v-if="progress > 0" class="segment-seeker"></span> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { getIntervalTime, getFormattedTime } from 'src/utils/outline'
import { useStationStore } from 'src/stores/station'
import {
  CaretRightOutlined,
  PauseOutlined,
  DeleteOutlined,
  StepForwardOutlined,
  StepBackwardOutlined
} from '@ant-design/icons-vue'

export default {
  props: [
    'segmentId',
    'count',
    'selected',
    'videoUrl',
    'segmentPlay',
    'durationTime',
    'isUpdating',
    'deleteCycleId',
    'framePerSec',
    'wrapperStyles',
    'styles'
  ],
  emits: ['selectCycle', 'deleteCycle'],
  components: {
    CaretRightOutlined,
    PauseOutlined,
    DeleteOutlined,
    StepForwardOutlined,
    StepBackwardOutlined
  },
  setup() {
    return {
      getIntervalTime,
      getFormattedTime
    }
  },
  data() {
    return {
      progress: 0,
      selectedSegment: null,
      isPlayingSegment: false,
      segmentPlayInterval: null,
      videoVisible: false
    }
  },
  beforeUnmount() {
    this.pauseSegment()
    clearInterval(this.segmentPlayInterval)
  },
  watch: {
    segmentPlay() {
      this.onVideoMetaLoaded()
    }
  },
  methods: {
    handleSelectCycle() {
      if (this.isUpdating) return
      if (this.deleteCycleId) return
      this.$emit('selectCycle', this.segmentId)
    },

    handleDeleteCycle() {
      if (this.deleteCycleId) return
      if (this.isUpdating) return
      this.$emit('deleteCycle', this.segmentId)
    },

    getInitialTime() {
      if (this.$refs.video) {
        return getFormattedTime(this.$refs.video.currentTime)
      } else {
        return getIntervalTime(this.progress * 1000)
      }
    },

    onVideoMetaLoaded() {
      this.progress = 0
      if (!this.$refs.video || !this.segmentPlay) return
      this.$refs.video.currentTime = this.segmentPlay.start
      if (!this.$refs.video.paused) this.$refs.video.pause()
      clearInterval(this.segmentPlayInterval)
      this.segmentPlayInterval = null
      this.videoVisible = true
    },

    pauseSegment() {
      if (!this.$refs.video.paused && this.isPlayingSegment) this.$refs.video.pause()
      clearInterval(this.segmentPlayInterval)
    },

    async playSegment() {
      if (!this.videoVisible) return
      if (this.$refs.video.paused && !this.isPlayingSegment) this.$refs.video.play()
      this.segmentTimeUpdate()
      if (this.segmentPlayInterval) clearInterval(this.segmentPlayInterval)
      this.segmentPlayInterval = setInterval(this.segmentTimeUpdate, 100 / this.framePerSec)
    },

    segmentTimeUpdate() {
      if (this.progress >= this.durationTime) {
        this.progress = 0
        this.$refs.video.currentTime = this.segmentPlay.start
        this.pauseSegment()
        return
      }
      if (this.$refs.video) this.progress = this.$refs.video.currentTime - this.segmentPlay.start
    },

    handleMoveForward() {
      if (!this.videoVisible) return
      const { video } = this.$refs
      if (video.currentTime + 3 >= this.segmentPlay.end) {
        video.currentTime = this.segmentPlay.end
        this.progress = 0
        this.pauseSegment()
        return
      }
      this.$refs.video.currentTime = video.currentTime + 3
      this.progress = this.$refs.video.currentTime - this.segmentPlay.start
    },

    handleMoveBackward() {
      if (!this.videoVisible) return
      const { video } = this.$refs
      if (video.currentTime - 3 <= this.segmentPlay.start) {
        video.currentTime = this.segmentPlay.start
        this.progress = 0
        this.pauseSegment()
        return
      }
      this.$refs.video.currentTime = video.currentTime - 3
      this.progress = this.$refs.video.currentTime - this.segmentPlay.start
    }
  }
}
</script>

<style scoped>
.preview-segment-video {
  width: 100%;
  border: 1px solid lightgray;
  pointer-events: none;
  border-radius: 0.5em;
  max-height: 350px;
  display: block;
}

.segment-seeker {
  position: absolute;
  right: 0px;
  z-index: 10;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  bottom: -2px;
  cursor: pointer;
}

.video-icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -0.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: large;
  cursor: pointer;
}

.video-left {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 10px;
  top: 10px;
}

.videoName {
  color: #fff;
  font-weight: 500;
  font-size: 16.4px;
  max-width: 80%;
  line-height: 1.4;
}

.segment-count {
  width: 50px;
  height: 50px;
  z-index: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 200ms ease-in;
  cursor: pointer;
}

.segment-count:hover,
.selected-segment {
  background-color: rgb(24, 144, 255) !important;
  color: #fff;
}

.disabled-count {
  pointer-events: none;
}

.delete-seg {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
}

.player-icons {
  font-size: 20px;
  cursor: pointer;
}
</style>