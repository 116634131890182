import axios from 'axios'
import URL from './baseUrl'
import { getAuthConfig, getUploadConfig } from './config'

export function getBaseUrl() {
  var hostURL = localStorage.getItem('baseURL')
  if (hostURL == null) {
    localStorage.setItem('baseURL', URL.prodHostUrl)
    hostURL = localStorage.getItem('baseURL')
  }
  return hostURL
  // return URL.localHostUrl
}

export const baseURL = getBaseUrl()

const axiosInstance = axios.create({
  baseURL
})

async function get(url, queryParams = {}, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.get(url, {
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function download(url, queryParams = {}, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.get(url, {
      responseType: 'blob',
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function post(url, payload, spinner = false, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.post(url, payload, {
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function put(url, payload, spinner = true, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.put(url, payload, {
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function patch(url, payload, spinner = true, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.patch(url, payload, {
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function remove(url, queryParams = {}, spinner = true, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.delete(url, {
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function upload(
  url,
  payload,
  stateObj = {},
  fileName = '',
  spinner = true,
  queryParams = {}
) {
  const config = getUploadConfig(stateObj, fileName)
  try {
    const { data } = await axiosInstance.post(url, payload, {
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

export default { get, post, put, patch, remove, upload, download }
