import { getConfig, getAuthConfig, sessionAuthConfig, getAuth } from './config'
import axios from 'axios'
import URL from './baseUrl'

const baseURL = URL.authServerUrl

const axiosInstance = axios.create({
  baseURL
})

async function get(url, queryParams = {}, config) {
  try {
    const { data } = await axiosInstance.get(url, {
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function post(url, payload, config) {
  try {
    const { data } = await axiosInstance.post(url, payload, {
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function patch(url, payload, config) {
  try {
    const { data } = await axiosInstance.patch(url, payload, {
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function put(url, payload, config) {
  try {
    const { data } = await axiosInstance.put(url, payload, {
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function remove(url, queryParams = {}, spinner = true, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.delete(url, {
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

const config = getConfig()

async function signIn(payload) {
  return await post('account/login', payload, config)
}

async function signUp(payload) {
  return await post('account/register', payload, getAuthConfig())
}

async function getUser(queryParams) {
  return await get(`account/users_list`, queryParams , getAuthConfig())
}
async function fetchRole() {
  return await get('account/roles/', {}, getAuthConfig())
}

async function assignUserWithOrg(data) {
  return await put('account/assign_user_org', data, getAuthConfig())
}

async function updateDeleteUser(email) {
  return await remove('account/update-delete/' + `${email}/`)
}

async function logout() {
  return await patch('account/logout/', {}, getAuthConfig())
}

async function forgotPassword(payload) {
  return await patch(`account/forgot_password/`, payload, config)
}

async function azureSignIn(payload) {
  return await post('account/azure-signin/', payload, config)
}

async function changePassword(payload) {
  return await patch(`account/reset_password/`, payload, false, config)
}

async function getUsers(params) {
  return await get('account/users_list', params, getAuthConfig())
}

async function getPresignedUrl(payload) {
  return await post('generic/generate_new_url/', payload, getAuthConfig())
}

async function checkAWSSubscription(payload) {
  return await get('account/check_aws_subscription_expiry', payload, getAuthConfig())
}

const getPresignedPostURL = async (payload) => {
  return await post('generic/generate_presigned_post/', payload, sessionAuthConfig())
}

const getVideoMetaData = async (payload) => {
  return await post('organization/get_video_metadata', payload, getAuthConfig())
}

export default {
  signIn,
  signUp,
  logout,
  forgotPassword,
  azureSignIn,
  changePassword,
  getUser,
  getUsers,
  getPresignedUrl,
  checkAWSSubscription,
  getPresignedPostURL,
  getVideoMetaData,
  fetchRole,
  assignUserWithOrg,
  updateDeleteUser,
}
