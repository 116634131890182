<template>
  <a-modal id="suggestion-question-modal" v-model:open="showConfirmModal">
    <p class="px-2 py-3">
      Do you want to answer a few questions and generate improvement recommendation?
    </p>
    <template #footer>
      <a-space class="d-flex w-100">
        <a-button @click="showConfirmModal = false"> No </a-button>
        <a-button type="primary" @click="handleConfirmImprovement"> Yes </a-button>
      </a-space>
    </template>
  </a-modal>

  <div
    v-if="loading && !isDemoOngoing"
    class="h-100 d-flex align-items-center justify-content-center"
  >
    <a-spin size="large" />
  </div>

  <div v-else id="sd-result-time-and-motion-study" class="p-4">
    <!-- Cycle details -->
    <a-row>
      <a-col span="16">
        <a-typography-title :level="4">Time and Motion Study Result</a-typography-title>
        <a-space class="my-3" :size="15">
          <span
            class="d-flex align-items-center"
            v-for="stat in timeAndMotionStats"
            :key="stat.title"
          >
            <a-statistic
              :title="stat.title"
              :value="stat.value"
              :style="{ fontWeight: 500 }"
              :valueStyle="{ fontSize: '28px', textAlign: 'center' }"
            >
            </a-statistic>
            <a-divider v-if="stat.index < 2" type="vertical" :style="{ height: '50px' }" />
          </span>
        </a-space>
      </a-col>
      <a-col span="8" class="text-end">
        <a-space>
          <a-button
            type="primary"
            :loading="isDownloading"
            @click="downloadExcelSheet"
            class="d-flex align-items-center justify-content-center"
            style="display: flex; align-items: center"
          >
            Download Excel
            <template #icon>
              <DownloadOutlined />
            </template>
          </a-button>
        </a-space>
      </a-col>
    </a-row>

    <!-- Charts -->
    <a-row :gutter="[24, 24]" class="mt-2">
      <a-col span="12">
        <a-card id="step-times-chart" title="Step Times Per Cycle" size="small" hoverable>
          <BarChart
            :title="'Bar chart'"
            :data="!isDemoOngoing ? stepsTimePerCycle : stationDesignDummyObject.stepsTimePerCycle"
          />
        </a-card>
      </a-col>
      <a-col span="12">
        <a-card
          id="value-pie-chart"
          title="Value Added Time as Percentage of Cycle"
          size="small"
          hoverable
        >
          <PieChart
            :data="
              !isDemoOngoing ? valueAddedPieChart : stationDesignDummyObject.valueAddedPieChart
            "
          />
        </a-card>
      </a-col>
      <a-col span="24">
        <StepsTable />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { useStationStore } from 'src/stores/station'
import { useClassificationStore } from 'src/stores/classification'
import { useSegmentationStore } from 'src/stores/segmentation'
import { DownloadOutlined } from '@ant-design/icons-vue'
import BarChart from './BarChart.vue'
import PieChart from './PieChart.vue'
import StepsTable from './StepsTable.vue'
import eventBus from 'src/utils/eventBus'
import ExcelService from 'src/services/excel'
import { downloadS3File } from 'src/utils/helpers'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
  inject: ['nextTab', 'showError', 'showInfo'],
  components: {
    BarChart,
    PieChart,
    StepsTable,
    DownloadOutlined
  },

  data() {
    return {
      loading: true,
      showConfirmModal: false,
      isDownloading: false
    }
  },

  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationStore, [
      'newStudy',
      'newStudyId',
      'stationDesignDummyObject',
      'workCycles',
      'masterCycle',
      'studyVideoUrl'
    ]),
    ...mapState(useSegmentationStore, [
      'stationLayoutSuggestion',
      'valueAddedPieChart',
      'stepsTimePerCycle'
    ]),
    ...mapState(useClassificationStore, ['therbligs']),

    timeAndMotionStats() {
      const {
        isDemoOngoing,
        stationLayoutSuggestion,
        stationDesignDummyObject: { stationLayoutSuggestion: dummyStationLayoutSuggestion }
      } = this

      if (
        !isDemoOngoing &&
        (!stationLayoutSuggestion || !Object.keys(stationLayoutSuggestion)?.length)
      ) {
        return []
      }

      return [
        {
          index: 0,
          title: 'Average Cycle Time',
          value: !isDemoOngoing
            ? stationLayoutSuggestion.actual_cycle_time
            : dummyStationLayoutSuggestion.actual_cycle_time
        },
        {
          index: 1,
          title: 'Value Added Percentage',
          value: !isDemoOngoing
            ? stationLayoutSuggestion.valueAddPercentage
            : dummyStationLayoutSuggestion.totalValueAddTime
        },
        {
          index: 2,
          title: 'No. Of Steps',
          value: !isDemoOngoing
            ? stationLayoutSuggestion.no_of_steps
            : dummyStationLayoutSuggestion.no_of_steps
        }
      ]
    }
  },

  watch: {
    masterCycle(cycle) {
      if (!cycle || !Object.keys(cycle)?.length) return
      this.getCycleSegments(cycle.cycle_no)
    }
  },

  methods: {
    ...mapActions(useStationStore, ['fetchWorkCyles', 'updateStudy']),
    ...mapActions(useSegmentationStore, ['fetchAllSteps', 'getCycleSegments']),
    ...mapActions(useClassificationStore, ['getTherbligs']),

    async getData() {
      let promises = []
      promises.push(this.fetchWorkCyles())
      promises.push(this.getTherbligs())
      await Promise.all(promises)
      await this.fetchAllSteps()
    },

    handleShowImprovementConfirmModal() {
      if (this.loading) return
      if (!this.newStudy.get_improvement) this.showConfirmModal = true
      else this.nextTab()
    },

    async handleConfirmImprovement() {
      await this.updateStudy({ get_improvement: true })
      this.nextTab()
    },

    async downloadExcelSheet() {
      this.isDownloading = true
      try {
        const [error, data] = await ExcelService.getStationDesignSheet(this.newStudyId)
        if (error || !data.data) throw new Error('Invalid response from API')
        else await downloadS3File(data.data)
      } catch (error) {
        toast.error('Something went wrong, Please try later.')
      } finally {
        this.isDownloading = false
      }
    }
  },
  async created() {
    eventBus.$on('timeStudy-next-page', this.handleShowImprovementConfirmModal)
    await this.getData()
    this.loading = false
  },
  beforeUnmount() {
    eventBus.$off('timeStudy-next-page', this.handleShowImprovementConfirmModal)
  }
}
</script>
