<template>
  <div v-if="loading" class="d-flex justify-content-center align-items-center w-100">
    <a-spin size="large" />
  </div>

  <!-- are process running  -->
  <a-row v-else-if="newStudy.status == processStatus.Failed">
    <a-col span="24" class="loading" style="gap: 0px; align-items: start">
      <a-typography-title class="mt-2" :level="4">
        Our AI models couldn't process this video. Please contact support with the Study ID.
      </a-typography-title>
      <div style="width: 100%" className="d-flex justify-content-center align-items-center mt-3">
        <a-button @click="handleCloseModal" type="primary" size="medium" className="mx-auto">
          <span>Close</span>
        </a-button>
      </div>
    </a-col>
  </a-row>

  <a-row class="w-100" v-else-if="!loading && areSuggestionProcessRunning">
    <a-col
      span="24"
      class="loading"
      style="gap: 0px; align-items: start"
      v-if="areSuggestionProcessRunning"
    >
      <a-typography-title class="mt-2" :level="4">
        Hang Tight! We are analyzing your video(s).
      </a-typography-title>

      <span class="mt-4"></span>

      <div class="d-flex flex-column" style="gap: 2em">
        <div v-for="status in progressStatuses" :key="status.title" class="progress-item">
          <a-progress type="circle" :percent="parseInt(status.progress)" :size="50" />
          <a-typography-title
            :level="5"
            class="d-flex align-items-center"
            style="text-wrap: nowrap; margin-left: 1em"
          >
            {{ status.title }}
          </a-typography-title>
        </div>
      </div>
    </a-col>
  </a-row>

  <a-row v-else id="sd-improvement-screen" class="w-100">
    <a-col span="6" class="d-flex flex-column py-2">
      <a-list
        bordered
        :data-source="!isDemoOngoing ? steps : stationDesignDummyObject.stepsAnalysis"
        size="large"
        class="app-list"
      >
        <template #header>
          <span class="list-header py-3">Step Analysis</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            class="step-item m-2"
            :class="{
              disable: currentStep === index
            }"
            @click="handleClickStep(index)"
          >
            <a-list-item-meta>
              <template #title>
                <a-typography-text
                  class="w-100 station-design-list-item-header"
                  :class="{ disable: currentStep === index }"
                >
                  {{ item.name ? item.name : `Step ${index + 1}` }}
                  <small class="d-flex w-100">{{ getStepsMeta(item.step_index) }}</small>
                </a-typography-text>
              </template>
              <template #description>
                <a-space direction="vertical" v-if="item.step_action_association.length">
                  <span class="station-design-list-item-header">
                    {{
                      !isDemoOngoing
                        ? getValueAssessment(item.step_action_association, item.step_index)
                        : 'Value-add'
                    }}
                    :
                    <span>({{ getStepActions(item.step_action_association) }})</span>
                  </span>
                </a-space>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>

    <a-col span="18" class="d-flex flex-column py-2">
      <div class="improvement-container">
        <div class="improvement-step-container">
          <div class="step-info-container mb-1 mx-1">
            <div>
              <a-typography-text v-if="!isDemoOngoing" strong>
                Step {{ steps[currentStep]?.step_index + 1 }} &nbsp;&nbsp;
              </a-typography-text>
              <a-typography-text v-else strong>
                Step
                {{ stationDesignDummyObject.stepsAnalysis[currentStep]?.step_index + 1 }}
              </a-typography-text>

              <a-typography-title :level="3" class="m-0">
                {{
                  (isDemoOngoing
                    ? stationDesignDummyObject.stepsAnalysis[currentStep]?.name
                    : steps[currentStep]?.name) || 'Untitled'
                }}
              </a-typography-title>
            </div>

            <div
              v-if="!!Object.keys(selectedActionQuestions).length || isDemoOngoing"
              class="d-flex flex-column flex-grow-1"
            >
              <a-typography-title :level="3" class="my-2">
                Questions ({{ numberOfQuestions }})
              </a-typography-title>
              <a-collapse v-model:activeKey="activeQuestionTab">
                <a-collapse-panel
                  v-for="(action, idx) in Object.entries(
                    !isDemoOngoing
                      ? selectedActionQuestions
                      : stationDesignDummyObject.selectedActionQuestions
                  )"
                  class="action-collapse"
                  :key="idx"
                  :header="action[0]"
                >
                  <template #header> </template>
                  <a-list bordered :data-source="action[1]" size="small">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <a-typography-text>{{ item.question }}</a-typography-text>
                        <template #actions>
                          <a-space>
                            <a-button
                              :type="item.answer === true ? 'primary' : 'default'"
                              :loading="
                                questionBeingAnswered?.answer === true &&
                                questionBeingAnswered?.questionId === item.id
                              "
                              @click="handleAnswer(item.action, item.id, true)"
                            >
                              Yes
                            </a-button>
                            <a-button
                              :type="item.answer === false ? 'primary' : 'default'"
                              :loading="
                                questionBeingAnswered?.answer === false &&
                                questionBeingAnswered?.questionId === item.id
                              "
                              @click="handleAnswer(item.action, item.id, false)"
                            >
                              No
                            </a-button>
                          </a-space>
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>

          <div style="position: relative" class="d-flex flex-column align-items-center">
            <div class="videoWrapper">
              <SegmentVideo
                v-for="(cycle, index) in selectedStepCycles"
                :key="cycle.id"
                :video-url="!isDemoOngoing ? cycle.url : stationDesignDummyObject.url"
                :segment-play="segmentPlay"
                :duration-time="segmentPlay.duration"
                :frame-per-sec="cycle.videoFPS"
                :styles="{ width: '300px', maxHeight: '260px' }"
                :wrapperStyles="{
                  display: currCycle === index ? 'flex !important' : 'none !important'
                }"
              />
            </div>

            <div class="mt-2 w-100 position-relative" v-if="selectedStepCycles?.length">
              <span class="d-flex gap-2 position-absolute start-50 translate-middle-x">
                <LeftOutlined
                  class="cycle-nav-icon"
                  :class="{ disabled: currCycle <= 0 }"
                  @click="handleCycleChange(-1)"
                />
                <span class="lh-1 fs-5">
                  {{ currCycle + 1 }} / {{ selectedStepCycles?.length }}
                  <sub
                    style="font-size: 14px; bottom: -1rem"
                    class="position-absolute"
                    v-if="currStepObj?.work_cycle?.is_master"
                    >(Master)</sub
                  >
                </span>
                <RightOutlined
                  :class="{ disabled: currCycle === selectedStepCycles?.length - 1 }"
                  class="cycle-nav-icon"
                  @click="handleCycleChange(1)"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { valueAssessment } from '../config'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { mapActions, mapState } from 'pinia'
import { useClassificationStore } from 'src/stores/classification'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { useFileStore } from 'src/stores/file'
import { useSegmentationStore } from 'src/stores/segmentation'
import {
  getAssessmentBasedOnPrecedence,
  getStepCyclesTime,
  getMedianAndStandardDeviation
} from 'src/utils/helpers'
import { useToast } from 'vue-toastification'
import { processStatus } from '../config'
import eventBus from 'src/utils/eventBus'
import SegmentVideo from './SegmentVideo.vue'

export default {
  inject: ['nextTab', 'showError', 'showInfo'],
  components: {
    LeftOutlined,
    RightOutlined,
    SegmentVideo
  },
  setup() {
    return {
      toast: useToast(),
      valueAssessment,
      processStatus
    }
  },
  data() {
    return {
      steps: [],
      currentStep: 0,
      selectedActionQuestions: [],
      loading: true,
      processStatusPolling: null,

      isPlayingSegment: false,
      activeQuestionTab: [0],
      segmentPlay: {
        progress: 0,
        start: 0,
        end: 0
      },
      questionBeingAnswered: null,
      // step cycle
      selectedStepCycles: [],
      currCycle: 0,
      currStepObj: null
    }
  },

  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useClassificationStore, ['getQuestions', 'idToActionName']),
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'newStudyId',
      'newStudy',
      'selectedMasterCycleId',
      'workCycles',
      'masterCycle',
      'studyVideoUrl',
      'handTrackingStatus',
      'homographyStatus',
      'areSuggestionProcessRunning',
      'suggestionProcessInProgress',
      'masterCycleVideoId',
      'studyFilesObject',
      'isCell',
      'operatorTrackingStatus'
    ]),
    ...mapState(useSegmentationStore, ['segmentationData', 'stepToCyclesSegmentation']),

    videoFPS() {
      if (!this.masterCycleVideoId || !this.studyFilesObject) return
      const fps = this.studyFilesObject[this.masterCycleVideoId]?.fps
      return fps || 30
    },

    numberOfQuestions() {
      let noOfQues
      let questions = this.isDemoOngoing
        ? this.stationDesignDummyObject.selectedActionQuestions
        : this.selectedActionQuestions

      noOfQues = Object.values(questions)?.reduce((res, el) => res + el.length, 0)
      return Number.isNaN(noOfQues) ? 0 : noOfQues
    },

    progressStatuses() {
      let processes = []
      if (this.isCell) {
        processes = [
          {
            progress: this.operatorTrackingStatus?.progress || 0,
            isRunning: this.operatorTrackingStatus?.name !== processStatus.Finished,
            title: 'Tracing operator movements.'
          }
        ]
      } else {
        processes = [
          {
            progress: this.handTrackingStatus?.progress || 0,
            isRunning: this.handTrackingStatus?.name !== processStatus.Finished,
            title: 'Tracing operator movements.'
          },
          {
            progress: this.homographyStatus?.progress || 0,
            isRunning: this.homographyStatus?.name !== processStatus.Finished,
            title: 'Generating improvement suggestions/questions.'
          }
        ]
      }
      return processes
    }
  },
  watch: {
    segmentationData(segments) {
      this.steps = segments
        .filter((segment) => segment.work_cycle.id === this.selectedMasterCycleId)
        ?.sort((a, b) => a.step_index - b.step_index)
      this.updateStepActionData()
      this.handleStepChange(this.currentStep)
    },

    currentStep(value) {
      if (typeof value === 'number') this.handleStepChange(value)
    },

    masterCycle(cycle) {
      if (!cycle || !Object.keys(cycle)?.length) return
      this.getCycleSegments(cycle.cycle_no)
    }
  },
  methods: {
    ...mapActions(useFileStore, ['fetchPresignedUrl']),
    ...mapActions(useClassificationStore, ['getTherbligs']),
    ...mapActions(useStationStore, [
      'fetch5W1HQuestions',
      'fetchStudy',
      'fetchWorkCyles',
      'setSuggestionProcessRunning'
    ]),
    ...mapActions(useSegmentationStore, [
      'fetchAllSteps',
      'addedQuestionAnswer',
      'getCycleSegments',
      'updateSegmentInList'
    ]),

    handleCloseModal() {
      this.$router.push({ name: 'studies' })
    },

    getStepsMeta(stepIndex) {
      if (!this.steps.length || !this.steps[stepIndex]) return
      if (!this.stepToCyclesSegmentation || !Object.keys(this.stepToCyclesSegmentation)?.length)
        return
      const cycle_seg = this.stepToCyclesSegmentation[stepIndex]
      const segCycleTime = getStepCyclesTime(cycle_seg)
      const { median, std } = getMedianAndStandardDeviation(segCycleTime)
      return `Time: ${median} ± ${Number(std.toFixed(2))} (s)`
    },

    handleClickStep(index) {
      this.currentStep = index
    },

    setSelectedStepCycles(currStepIndex) {
      this.selectedStepCycles = this.stepToCyclesSegmentation[currStepIndex] || []
    },

    handleCycleChange(count = 0) {
      this.currCycle = this.currCycle + count
      const segment = this.selectedStepCycles[this.currCycle]
      if (!segment) return
      this.currStepObj = segment
      this.setCurrentSegment(segment)
    },

    handleStepChange(index) {
      if (!this.steps[index]) return
      this.currentStep = index
      this.currCycle = 0
      // segment video
      this.setSelectedStepCycles(index)
      this.handleCycleChange()
      // segment actions
      const segment = this.steps[index]
      const { step_action_association } = segment
      this.updateSelectedActions(step_action_association)
      this.activeQuestionTab = [0]
    },

    setCurrentSegment(segment) {
      if (!segment) return
      const { segment_start, segment_end } = segment
      this.segmentPlay = {
        progress: 0,
        start: segment_start / this.videoFPS,
        end: segment_end / this.videoFPS,
        duration: Number(((segment_end - segment_start) / this.videoFPS).toFixed(2))
      }
    },

    updateStepActionData() {
      if (!this.steps[this.currentStep]) return
      const { step_action_association } = this.steps[this.currentStep]
      this.updateSelectedActions(step_action_association)
    },

    updateSelectedActions(step_action_association) {
      if (!step_action_association) return
      const selectedActions = step_action_association?.map((el) => el.action)
      const questions = this.getQuestions(selectedActions)
      const answers = step_action_association
        .reduce((res, el) => [...res, ...el.answers], [])
        .reduce((res, el) => {
          res[el.question] = el.answer
          return res
        }, {})

      const qs = questions.reduce((res, el) => [...res, ...el.questions], [])
      const categories = {}
      qs.forEach((q) => {
        if (!categories[q.category]) categories[q.category] = []
        categories[q.category].push({ ...q, answer: answers[q.id] })
      })
      this.selectedActionQuestions = categories
    },

    getValueAssessment(step_action_association, stepIndex = 0) {
      if (!this.steps?.length) return
      let step = this.isDemoOngoing
        ? this.stationDesignDummyObject.stepsAnalysis[stepIndex]
        : this.steps[stepIndex]
      let actionType = valueAssessment.VA
      if (step.value_type && step.value_type_by_user) {
        actionType = step.value_type
      } else if (step_action_association?.length) {
        const temp = step_action_association.map((el) => el.value_type)
        actionType = getAssessmentBasedOnPrecedence(temp)
      }
      return actionType
    },

    async handleAnswer(actionId, questionId, answer) {
      if (this.isDemoOngoing) return
      this.questionBeingAnswered = {
        questionId,
        answer
      }
      const { id } = this.steps[this.currentStep]
      const payload = { question_id: questionId, step_id: id, action_id: actionId, answer }
      const res = await this.addedQuestionAnswer(payload)
      this.updateStepsAfterAnswer(payload, res)
      this.updateStepActionData()
      this.questionBeingAnswered = null
    },

    updateStepsAfterAnswer(payload, data) {
      const temp = [...this.steps]
      const { step_id, action_id } = payload
      const index = temp.findIndex(({ id }) => id === step_id)

      const stepActionAssociations = temp[index]?.step_action_association
      const stepActionAssociationIndex = stepActionAssociations?.findIndex(
        ({ step, action }) => step === step_id && action === action_id
      )
      if (stepActionAssociationIndex < 0) {
        console.log('action association index not found!')
      }
      const answerIndex = stepActionAssociations[stepActionAssociationIndex].answers?.findIndex(
        (el) => el.question === data.question && el.step_action === data.step_action
      )
      if (answerIndex === -1) stepActionAssociations[stepActionAssociationIndex].answers.push(data)
      else stepActionAssociations[stepActionAssociationIndex].answers[answerIndex] = data

      temp[index]['step_action_association'] = stepActionAssociations
      this.steps = temp
    },

    getStepActions(step_action_association) {
      if (!step_action_association.length) return null
      const actions = step_action_association
        ?.map((el) => el.action)
        .map((actionId) => this.idToActionName[actionId])
      return actions.join(', ')
    },

    goToNextScreen() {
      // TODO: Implementation missing
      if (this.loading) return
      this.nextTab()
    },

    listenProcessesStatus() {
      if (this.processStatusPolling) {
        clearInterval(this.processStatusPolling)
      }
      this.getProcessesStatus()
      this.processStatusPolling = setInterval(this.getProcessesStatus, 5000)
    },

    async getProcessesStatus() {
      if (this.isPolled) return
      this.isPolled = true
      await this.fetchStudy(this.newStudyId)
      this.isPolled = false

      const { handTrackingStatus, homographyStatus, operatorTrackingStatus } = this
      const processes = this.isCell
        ? [operatorTrackingStatus.name]
        : [handTrackingStatus.name, homographyStatus.name]

      this.loading = false
      this.setSuggestionProcessRunning(true)

      const areProcessesCompleted = processes.every((el) => el === processStatus['Finished'])
      const isFailed = processes.some((el) => el === processStatus['Failed'])
      if (areProcessesCompleted) {
        clearInterval(this.processStatusPolling)
        this.setSuggestionProcessRunning(false)
        this.loadAllData()
      } else if (isFailed) {
        this.setSuggestionProcessRunning(false)
      }
    },

    async loadAllData() {
      this.loading = true
      await this.fetchStudy(this.newStudyId)
      await this.fetchWorkCyles()
      await this.getTherbligs()
      await this.fetchAllSteps()
      await this.fetch5W1HQuestions(this.newStudyId)
      this.loading = false
    },

    setEvents() {
      eventBus.$on('improvements-next-page', this.goToNextScreen)
    }
  },

  async created() {
    if (this.isDemoOngoing) return
    this.setEvents()
    this.listenProcessesStatus()

    if (this.newStudy.status === processStatus.Failed) {
      this.loading = false
      clearInterval(this.processStatusPolling)
    } else if (this.suggestionProcessInProgress) {
      setTimeout(() =>
        this.showInfo(
          'Your video will be processed in some time. Feel free to close the tab and check back in a bit.'
        )
      )
    }
  },

  beforeUnmount() {
    eventBus.$off('improvements-next-page', this.goToNextScreen)
    clearInterval(this.processStatusPolling)
  }
}
</script>
<style>
.station-design-list-item-header {
  font-weight: 600;
  font-size: 12.8px;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.progress-item {
  /* width: 50%; */
  display: flex;
}

.segment-video {
  width: 300px;
  max-height: 260px;
  object-fit: cover;
  border: 1px solid lightgray;
  border-radius: 0.5em;
}

.app-list {
  height: 68vh;
  display: flex;
  flex-direction: column;
}

.app-list > .ant-list-header {
  /* background: lightgray !important;
    font-weight: 600 !important; */
  background: #fafafa !important;
  font-weight: 600 !important;
  padding: 10px 10px 10px 10px !important;
  font-size: 14px;
  color: #0b072d;
  text-align: left;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.app-list > .ant-spin-nested-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-list > .ant-spin-nested-loading > .ant-spin-container {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.step-item {
  cursor: pointer;
  background: #f8f9fa;
  border-radius: 5px;
}

.step-item.disable {
  background-color: #1890ff;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.improvement-step-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.step-info-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.video-player {
  position: absolute;
  height: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.control-container {
  display: flex;
  padding: 0 0.7em;
}

.load-center {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: large;
  cursor: pointer;
}

.action-collapse > .ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}
.action-collapse > .ant-collapse-header > div {
  display: flex !important;
}

.cycle-nav-icon {
  transform: translateY(2px);
  cursor: pointer;
}

.cycle-nav-icon.disabled {
  color: #828282;
  pointer-events: none;
}

.station-design-list-item-header .disabled {
  font-weight: 600;
  font-size: 12.8px;
  color: white;
}
</style>
