import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import moment from 'moment'
import { valueAssessment } from 'src/components/User/StationBalancing/config'
import { useHandTrackingStore } from 'src/stores/handTracking'
import { useSegmentationStore } from 'src/stores/segmentation'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { getIntervalTime } from './outline'

const defaultFormat = 'MM/DD/YY, hh:mm A'
const dateTimeFormat = 'MM/DD/YY-hh:mm:ss'
export const dateFormat = 'MM/DD/YYYY'

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj))

export const toLower = (value) => {
  return value && typeof value === 'string' ? value.toLowerCase() : value
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}
export const DownloadSheet = async (fileId) => {
  try {
    const response = await fetch(`/api/fetchUrlPrestigue/${fileId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.presigned_url;
  } catch (error) {
    console.error('Error fetching the pre-signed URL:', error);
    throw error;
  }
};

export const isObject = (obj) => obj !== undefined && obj !== null && obj.constructor == Object

export const getFormattedTime = (timeInSeconds) => {
  dayjs.extend(duration)
  const durationObject = dayjs.duration(timeInSeconds, 'seconds')
  const formattedTime = dayjs(durationObject.asMilliseconds()).format('mm:ss')
  return formattedTime
}

export const getAssessmentBasedOnPrecedence = (assessments) => {
  const { VA, NVA, NNVA } = valueAssessment
  if (assessments.includes(VA)) return VA
  else if (assessments.includes(NVA)) return NVA
  return NNVA
}

export const getTotalAmountSavedPerYear = (timeSavedInSeconds) => {
  const { totalCyclesPerYear, perHourCost } = useStationStore()
  const totalAmountSavedPerYear = ((timeSavedInSeconds * totalCyclesPerYear) / 3600) * perHourCost
  return totalCyclesPerYear ? Math.round(totalAmountSavedPerYear) : 0
}

export const segmentIdToSuggestions = () => {
  const { stepsDataForEditTimings } = useSegmentationStore()

  return stepsDataForEditTimings?.reduce((res, segment) => {
    const { id, actions_suggestions, segment_start, segment_end, videoFPS } = segment

    const start = segment_start / videoFPS
    const end = segment_end / videoFPS

    res[id] = !actions_suggestions.length ? [] : actions_suggestions.map((suggestion) => {
      let cost_saving_percentage = suggestion.cost_saving_percentage
        ? suggestion.cost_saving_percentage
        : 0

      const timeSavedPerCycle = ((end - start) * cost_saving_percentage) / 100
      const totalAmountSavedPerYear = getTotalAmountSavedPerYear(timeSavedPerCycle)

      return {
        ...suggestion,
        potential_savings: `${timeSavedPerCycle.toFixed(
          2
        )} sec, $${totalAmountSavedPerYear} per year`,
        timeSavedPerCycle,
        totalAmountSavedPerYear
      }
    })
    return res
  }, {})
}

export const flattenSegmentSuggestionandAnswers = (step) => {
  if (!step) return
  const { studyFilesObject, newStudy } = useStationStore()
  const cycleFileId = step.work_cycle.file ? step.work_cycle.file : newStudy.file.id //SYNC
  const fileDetails = cycleFileId ? studyFilesObject[cycleFileId] : null

  let stepAnswers = []

  const { step_action_association, actions_suggestions: actionsSuggestions, ...data } = step
  if (actionsSuggestions?.length) {
    stepAnswers = actionsSuggestions.reduce((res, el) => {
      return el.answers ? [...res, ...el.answers] : res;
    }, []);
  }
  const updatedActionAssociation = step_action_association?.map((s_associate) => ({
    ...s_associate,
    answers: stepAnswers.filter((ans) => ans?.step_action === s_associate?.id)
  })) || []

  const updateActionSuggestion = actionsSuggestions?.map((a_suggest) => {
    const { actions_suggestions, ...suggestion } = a_suggest
    return { ...suggestion, ...actions_suggestions?.[0] }
  }) || []

  return {
    ...data,
    step_action_association: updatedActionAssociation,
    actions_suggestions: updateActionSuggestion.length ? updateActionSuggestion : [],
    url: fileDetails ? fileDetails.url : '',
    videoFPS: fileDetails ? fileDetails.fps : 30
  }
}

export const getMotionEconomy = (segment, segmentDuration) => {
  const { step_index } = segment
  const { handTrackingData } = useHandTrackingStore()
  const data = {
    motion: { type: 'Economical Motion' },
    potentialSavings: {
      timeSavedPerCycle: 0,
      totalAmountSavedPerYear: 0
    }
  }
  if (!handTrackingData || !Object.keys(handTrackingData)?.length || !handTrackingData[step_index])
    return data
  const { motion_type, total_distance, image } = handTrackingData[step_index]
  data['image'] = image
  data['total_distance'] = total_distance

  if (motion_type === 'haphazard') {
    const timeSavedPerCycle = segmentDuration * 0.5
    const totalAmountSavedPerYear = getTotalAmountSavedPerYear(timeSavedPerCycle)

    data.motion.type = `Haphazard Motion (Potential Savings: ${timeSavedPerCycle.toFixed(
      2
    )} sec, $${totalAmountSavedPerYear}  per year)`

    data.motion['details'] =
      'It may be helpful to perform a 5S exercise, break this step into its sub-motions, and carefully organize the activity to make the motion smooth.'

    data['potentialSavings'] = {
      timeSavedPerCycle,
      totalAmountSavedPerYear
    }
  }

  return data
}

export const getSumOfMaxPotentialSavingsForSteps = () => {
  const { segmentationDatForSuggestions } = useSegmentationStore()
  const maxTimeSavedPerCycle = []
  const maxTotalAmountSavedPerYear = []

  segmentationDatForSuggestions?.forEach((el) => {
    let suggestionTime = []
    let totalSuggestionTime = []
    if (el.actionsSuggestions?.length) {
      suggestionTime = el.actionsSuggestions.map(el => el.timeSavedPerCycle)
      totalSuggestionTime = el.actionsSuggestions.map((el) => el.totalAmountSavedPerYear)
    }

    const temp = [
      ...suggestionTime,
      el.motionEconomy.potentialSavings.timeSavedPerCycle
    ]
    temp.sort((a, b) => a - b)
    maxTimeSavedPerCycle.push(temp.at(-1))

    const _temp = [
      ...totalSuggestionTime,
      el.motionEconomy.potentialSavings.totalAmountSavedPerYear
    ]
    _temp.sort((a, b) => a - b)
    maxTotalAmountSavedPerYear.push(_temp.at(-1))
  })

  const sumOfMaxTimeSavedPerCycle = maxTimeSavedPerCycle.every((e) => e != 0)
    ? maxTimeSavedPerCycle.reduce((res, el) => res + el, 0)
    : 0

  const sumOfMaxTotalAmountSavedPerYear = maxTotalAmountSavedPerYear.every((e) => e !== 0)
    ? maxTotalAmountSavedPerYear.reduce((res, el) => res + el, 0)
    : 0

  const potentialSavings = {
    timeSavedPerCycle: sumOfMaxTimeSavedPerCycle,
    totalAmountSavedPerYear: sumOfMaxTotalAmountSavedPerYear
  }
  return potentialSavings
}

export const getSumOfMaxPotentialSavingsForLayout = () => {
  const { potentialSavingsForLayoutSuggestions: potentialLayoutSuggestions } =
    useHandTrackingStore()

  if (!potentialLayoutSuggestions || !Object.keys(potentialLayoutSuggestions)?.length)
    return {
      timeSavedPerCycle: 0,
      totalAmountSavedPerYear: 0
    }

  const layoutSuggestions = Object.values(potentialLayoutSuggestions)

  const sumOfMaxTimeSavedPerCycle = layoutSuggestions
    .map((el) => el?.timeSavedPerCycle || 0)
    .reduce((res, el) => res + el)

  const sumOfMaxTotalAmountSavedPerYear = layoutSuggestions
    .map((el) => el?.totalAmountSavedPerYear || 0)
    .reduce((res, el) => res + el)

  return {
    timeSavedPerCycle: sumOfMaxTimeSavedPerCycle,
    totalAmountSavedPerYear: sumOfMaxTotalAmountSavedPerYear
  }
}

export const formatDate = (dateString, format = defaultFormat) => {
  if (!dateString) return ''
  return dayjs(dateString).format(format)
}

export const formatDateTime = (dateString, format = dateTimeFormat) => {
  if (!dateString) return ''
  return dayjs(dateString).format(format)
}

export const getFileIconFromType = (fileType) => {
  if (!fileType) return
  if (fileType.includes('plain')) return 'bi bi-file-earmark-text'
  else if (fileType.includes('image')) return 'bi bi-file-earmark-image'
  else if (fileType.includes('video')) return 'bi bi-file-earmark-play'
  else if (fileType.includes('audio')) return 'bi bi-file-earmark-music'
  else if (fileType.includes('pdf')) return 'bi bi-file-earmark-pdf'
  else return 'bi bi-file-earmark'
}

export const parseSize = (size) => {
  size *= 1024
  return size > 500 ? (size / 1024).toFixed(2) + ' MB' : size.toFixed(2) + ' KB'
}

export const parseFileData = (fileData) => {
  const { userIdToNameMap } = useUserStore()
  let parsedData = [
    { title: 'File Name', content: fileData.name },
    { title: 'Type', content: fileData.name.split('.').at(-1) },
    { title: 'Size', content: parseSize(fileData.meta?.size) },
    { title: 'Owner', content: userIdToNameMap[fileData.created_by] },
    { title: 'Uploaded at', content: formatDate(fileData.created) },
    { title: 'Tags', content: fileData.tag?.length ? fileData.tag.map((t) => t.id) : '-' }
  ]

  return parsedData
}

export const getVideoDuration = (duration) => {
  let totalSeconds = 0
  if (Number(duration)) {
    totalSeconds = Number(duration)
  } else if (duration.includes(':')) {
    const parts = duration.split(':')
    const hours = parseInt(parts[0], 10) || 0
    const minutes = parseInt(parts[1], 10) || 0
    const seconds = parseInt(parts[2], 10) || 0
    totalSeconds = hours * 3600 + minutes * 60 + seconds
  }
  return getIntervalTime(totalSeconds * 1000)
}

export const getMedianOfStepCyclesTime = (steps) => {
  // Extract the time values into an array
  const timeValues = steps.map((obj) => obj.seg_time)

  // Sort the array of time values
  timeValues.sort((a, b) => a - b)

  let median
  const mid = Math.floor(timeValues.length / 2)
  if (timeValues.length % 2 === 0) {
    median = (timeValues[mid - 1] + timeValues[mid]) / 2
  } else {
    median = timeValues[mid]
  }

  return Number(median?.toFixed(2))
}

export const getMeanOfStepCyclesTime = (steps) => {
  const sum = steps?.reduce((sum, step) => sum += step.seg_time, 0)
  const totalSteps = steps?.length
  const mean = sum / totalSteps
  return mean ? Number((mean).toFixed(2)) : 0
}

export const getStepCyclesTime = (segments) => {
  const sortedSegments = segments
    .slice()
    .sort((a, b) => a.work_cycle.cycle_no - b.work_cycle.cycle_no)
  return sortedSegments.map((s) => {
    const segmentStart = s.segment_start / s.videoFPS
    const segmentEnd = s.segment_end / s.videoFPS
    return {
      ...s,
      seg_time: segmentEnd - segmentStart
    }
  })
}

export const getCyclesDurations = (cycles) => {
  const arr = cycles.sort((a, b) => a.cycle_no - b.cycle_no)

  return arr.map((s) => {
    const segmentStart = Math.floor(s.segment_start)
    const segmentEnd = Math.floor(s.segment_end)
    return {
      ...s,
      seg_time: segmentEnd - segmentStart
    }
  })
}

// export const getStepsMeta = (stepIndex) => {
//   const { videoFPS } = useStationStore()
//   let { isDemoOngoing } = useUserStore()
//   let steps;
//   if (!isDemoOngoing) {
//     const { segmentationData } = useSegmentationStore()
//     steps = segmentationData
//   } else {
//     steps = useStationStore().stationDesignDummyObject.segmentationDatForSuggestions
//   }
//   if (!steps.length || !steps[stepIndex]) return
// }

export const getMedianAndStandardDeviation = (arrayValues) => {
  const median = getMeanOfStepCyclesTime(arrayValues)
  const stepsTimeSquareDiffSum = arrayValues.reduce(
    (res, el) => (res += Math.pow(el.seg_time - median, 2)),
    0
  )
  const std = Math.sqrt(stepsTimeSquareDiffSum / arrayValues.length)

  return {
    median,
    std
  }
}

export const getTimeStudyModels = (isCell) => {
  return isCell ? ['Segmentation', 'Segments Mapping'] : ['Segmentation', 'Classification', 'Segments Mapping']
}

export const getSuggestionModels = (isCell) => {
  return isCell ? ['Person Tracking'] : ['Hand Tracking', 'Ergonomics']
}

export const getAllModels = (isCell) => {
  return isCell ? ['Segmentation', 'Segments Mapping', 'Person Tracking'] : ['Segmentation', 'Classification', 'Segments Mapping', 'Hand Tracking', 'Ergonomics']
}

export const addStatusInStudy = (study) => {
  const { userIdToNameMap } = useUserStore()
  let obj = {
    ...study,
    time_created: formatDate(study.time_created, dateFormat),
    thumbnail: study.thumbnail ? study.thumbnail : study.thumbnail_path,
    files: !study.files?.length ? [study.file] : study.files, //SYNC
    // isCompleted: study.processes?.length
    //   ? study.processes.every((p) => p.status?.name === p.completed_status?.name)
    //   : false,
    // isProcessesRemaining: study.processes?.length
    //   ? study.processes.some((p) => (p.status ? p.status?.name !== p.completed_status?.name : true))
    //   : true,
    isTimeStudyCompleted: getTimeStudyStatus(study),
    isCompleted: getCompletedStatus(study),
    isProcessesRemaining: getProcessingStatus(study),
    isNotStarted: getPendingStatus(study),
    isFailed: study.processes?.length
      ? study.processes.some((p) => (p.status ? p.status?.name === 'Failed' : false))
      : false,
    station_id: study.station_id,
    no_of_steps: study.no_of_steps,
    created_by: userIdToNameMap[study.created_by] || '-'
  }
  obj['status'] = getStudyServicesStatus(obj)
  return obj
}

const getPendingStatus = (study) => {
  if (!study.processes?.length) return true
  let answer = true
  if (!study?.processes?.length) return answer
  const isCell = study.station?.type === 'cell'
  const SuggestionModels = getSuggestionModels(isCell)
  const TimeStudyModels = getTimeStudyModels(isCell)

  if (study.get_improvement) {
    answer = !study.station.no_of_work_regions || (study.station?.no_of_work_regions && study.processes
      .filter((p) => SuggestionModels.includes(p.process.name))
      .every((p) => p.status === null))
  } else {
    answer = study.processes
      .filter((p) => TimeStudyModels.includes(p.process.name))
      .every((p) => p.status === null)
  }
  return answer

}

const getProcessingStatus = (study) => {
  if (!study.processes?.length) return true
  const isCell = study.station?.type === 'cell'
  const TimeStudyModels = getTimeStudyModels(isCell)
  const BaseModels = getAllModels(isCell)
  let answer = false
  if (study.get_improvement) {
    const allModels = study.processes.filter((p) => BaseModels.includes(p.process.name))
    answer = allModels.some((p) => (p.status ? p.status.name !== p.completed_status.name : true))
  } else {
    answer = study.processes.some((p) => TimeStudyModels.includes(p.process?.name) && (p.status ? p.status?.name !== p.completed_status.name : true))
  }
  return answer
}

const getCompletedStatus = (study) => {
  if (!study.processes?.length) return false
  const isCell = study.station?.type === 'cell'
  const TimeStudyModels = getTimeStudyModels(isCell)
  const BaseModels = getAllModels(isCell)

  let answer = false
  if (study.get_improvement) {
    const allModels = study.processes.filter((p) => BaseModels.includes(p.process.name))
    answer = study.station.no_of_work_regions && allModels.every((p) => p.status?.name === p.completed_status?.name)
  } else {
    answer = study.processes.filter((p) => TimeStudyModels.includes(p.process?.name)).every((p) => p.status?.name === p.completed_status?.name)
  }
  return answer
}

const getTimeStudyStatus = (study) => {
  if (!study.processes?.length) return false
  const isCell = study.station?.type === 'cell'
  const TimeStudyModels = getTimeStudyModels(isCell)

  let answer = false
  answer = study.processes.filter((p) => TimeStudyModels.includes(p.process?.name))
    .every((p) => p.status?.name === p.completed_status?.name)
  return answer
}

export const getStudyServicesStatus = (record = {}, forColor = false) => {
  if (record.isCompleted) return forColor ? 'success' : 'Completed'
  else if (record.isFailed) return forColor ? 'error' : 'Failed'
  else if (record.isProcessesRemaining && !record.isNotStarted)
    return forColor ? 'processing' : 'Processing'
  else return forColor ? 'default' : 'Pending'
}

export const updateStudyCallTime = (studyId, stepsCount) => {
  let studyDict = localStorage.getItem('requestsCall')
  studyDict = studyDict ? JSON.parse(studyDict) : {}

  const currentTime = moment()
  studyDict[studyId] = {
    requestsCount: studyDict[studyId]?.requestsCount || 0,
    noOfSteps: stepsCount,
    lastRequestTime: currentTime
  }
  localStorage.setItem('requestsCall', JSON.stringify(studyDict))
}

export const encodeS3URI = (uri) => {
  const encodings = {
    '+': '%2B',
    '!': '%21',
    '"': '%22',
    '#': '%23',
    $: '%24',
    '&': '%26',
    "'": '%27',
    '(': '%28',
    ')': '%29',
    '*': '%2A',
    ',': '%2C',
    ':': '%3A',
    ';': '%3B',
    '=': '%3D',
    '?': '%3F',
    '@': '%40'
  }

  return uri.replace(/([+!"#$&'()*+,:;=?@])/gim, (match) => encodings[match])
}
export const fetchAndDownload = async (fileId) => {
  try {
    const presignedUrl = await DownloadSheet(fileId);
    if (presignedUrl) {
      downloadS3File(presignedUrl);
    }
  } catch (error) {
    console.error('Error during fetch and download:', error);
  }
};
export const downloadS3File = async (presigned_url) => {
  let link = document.createElement('a');
  link.setAttribute('href', presigned_url);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
