import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { dateFormat, formatDate } from 'src/utils/helpers'
import { setStations, setSwctChartConfig, getCsvFormattedData } from 'src/utils/lineBalancing'
import { lineBalancingDummyData } from 'src/components/User/DummyData/line-balancing'
import StationService from 'src/services/station'
import LineBalanceService from 'src/services/lineBalancing'
import ExcelService from 'src/services/excel'

export const useLineBalanceStore = defineStore('lineBalance', {
  state: () => ({
    lineStations: [],
    lineSteps: [],
    parentLineId: null,
    lineDetails: {},
    taktTime: 0,
    isCreatingNewLine: false,
    lines: [],
    isFetchingAllLines: false,
    totalLinesCount: 0,
    recommendations: [],
    selectedHistory: null,
    historyLineBalance: [],
    selectedLineIdToShowGraph: null, // not using anywhere => TODO: remove
    resultContentLoading: false,
    parentLineSteps: [],
    defaultLines: [],
    defaultLineId: null,
    balanceLineData: {},
    dummyListObject: {
      name: 'test coffee maker',
      id: 0,
      part_number: 'coffee maker',
      project_type: 'design',
      time_created: '03/05/2024',
      owner: 'John Doe'
    },
    lineBalancingDummyObject: lineBalancingDummyData,
    updateLineLoding: false
  }),

  getters: {
    linesList() {
      const { userIdToNameMap } = useUserStore()
      return this.lines.map((item, key) => {
        return {
          key: key + 1,
          name: item.name,
          id: item.id,
          part_number: item.part_number,
          stations: item.stations,
          project_type: item.project_type,
          is_processed: item.is_processed,
          time_created: formatDate(item.time_created, dateFormat),
          owner: userIdToNameMap[item.created_by] || '-'
        }
      })
    },

    doStepsHavePredecessors() {
      if (this.parentLineSteps.length === 1) return false
      // Check if all steps except the first one have predecessors
      for (let i = 1; i < this.parentLineSteps.length; i++) {
        if (
          !this.parentLineSteps[i].predecessor ||
          this.parentLineSteps[i].predecessor.length === 0
        ) {
          return false
        }
      }
      return true
    },

    isDetailsDataComplete() {
      const stationIds = this.lineStations.map((station) => station.id)
      const hasAllStationsHaveStep = stationIds.every((id) =>
        this.parentLineSteps?.some((step) => step.station === id)
      )
      const hasNonNullValueType = this.parentLineSteps?.filter((step)=>stationIds?.includes(step.station))?.every((step) => step.value_type !== null && step.name !== null)
      return hasAllStationsHaveStep && hasNonNullValueType && this.lineStations.length
    },

    unbalanceLineSelected() {
      return this.selectedHistory === this.defaultLineId
    },

    lineStationsForResults() {
      let stationResults = []

      console.log('line stations:', this.lineStations)
      if (this.unbalanceLineSelected && !this.isDesigningNewLine) {
        // UNBALANCED LINE (CURR WORK CONTENT)
        stationResults = this.lineStations.map((station) => {
          const stationSteps = this.lineSteps.filter(
            (step) => step.station === station.id && step.action_type !== 'Machine'
          )
          const [totalStationTime, stationValueAddTime] = this.getStationTotalAndValueAddTime(stationSteps)
          return {
            ...station,
            total_time: totalStationTime,
            stationValueAddTime: stationValueAddTime,
            steps: stationSteps.reduce((res, el) => {
              res[el.id] = el.name
              return res
            }, {}),
            station_steps: stationSteps.map((s) => s.id),
            data: {
              y: totalStationTime,
              name: station.name,
              utilizationPercentage: ((totalStationTime / this.taktTime) * 100).toFixed(2),
              valueAddedPercentage: ((stationValueAddTime / totalStationTime) * 100).toFixed(2),
              color: totalStationTime >= this.taktTime ? '#FF726F' : '#ADD8E6'
            },

          }
        })
        console.log('prev station results ->', stationResults)
      } else {
        // BALANCED LINE
        this.balanceLineData?.stations?.forEach((station) => {
          const stationStepsIds = station.station_steps
          const stationSteps = this.lineSteps.filter((s) => stationStepsIds.includes(s.id))
          const [totalStationTime, stationValueAddTime] = this.getStationTotalAndValueAddTime(stationSteps)
          const obj = {
            total_time: totalStationTime,
            stationValueAddTime: stationValueAddTime,
            steps: stationSteps.reduce((res, el) => {
              res[el.id] = el.name
              return res
            }, {}),
            station_steps: stationStepsIds,
            data: {
              y: totalStationTime,
              name: station.name,
              utilizationPercentage: ((totalStationTime / this.taktTime) * 100).toFixed(2),
              valueAddedPercentage: ((stationValueAddTime / totalStationTime) * 100).toFixed(2),
              color: totalStationTime >= this.taktTime ? '#FF726F' : '#ADD8E6'
            },
          }
          stationResults.push(obj)
        })
        console.log('new station results ->', stationResults)
      }
      return stationResults
    },

    operatorsForResults() {
      let operatorResults = []
      if (this.unbalanceLineSelected && !this.isDesigningNewLine) {
        let operators = new Set(this.lineStations.map((station) => station.operator))
        Array.from(operators).forEach((operator) => {
          const operatorStations = this.lineStationsForResults.filter(
            (item) => item.operator === operator
          )
          if (!operatorStations.length) return
          const obj = {
            name: operator,
            total_time: operatorStations.reduce((acc, curr) => acc + curr.total_time, 0),
            station_steps: operatorStations.map((ops) => ops.station_steps),
            steps: operatorStations.map((ops) => ops.steps).flat().reduce((res, el) => {
              res[el.id] = el.name
              return res
            }, {}),
            stations: operatorStations.map((station) => ({
              name: station.name,
              y: station.total_time,
              utilizationPercentage: ((station.total_time / this.taktTime) * 100).toFixed(2),
              valueAddedPercentage: ((station.stationValueAddTime / station.total_time) * 100).toFixed(2),
              color: station.total_time >= this.taktTime ? '#FF726F' : '#ADD8E6'
            })),
          }
          operatorResults.push(obj)
        })
        console.log('operators for result->', operatorResults)
      } else {
        operatorResults = this.lineStationsForResults?.map(({ data, ...res }) => ({
          ...res,
          stations: [{ ...data, color: data.total_time >= this.taktTime ? '#FF726F' : '#ADD8E6', }]
        }))
        console.log('balanced operators for result:', operatorResults)
      }
      return operatorResults
    },

    lineHeading() {
      const { name, part_number } = this.lineDetails
      return part_number + ' on ' + name
    },

    currentLineType() {
      return this.lineDetails.project_type
    },

    stationIdForWorkContent() {
      return this.lineStations[0]?.id
    },

    stepIdToIndexMap() {
      return this.parentLineSteps.reduce((res, el, index) => {
        res[el.id] = index + 1
        return res
      }, {})
    },

    stepIdToPredecessorMap() {
      return this.lineSteps.reduce((res, el) => {
        res[el.id] = [...el.predecessor]
        return res
      }, {})
    },

    stepIdToObjectMap() {
      return this.lineSteps.reduce((res, el) => {
        res[el.id] = el
        return res
      }, {})
    },

    stationIdToDictMap() {
      return this.lineStations.reduce((res, el) => {
        res[el.id] = el
        return res
      }, {})
    },

    // total_work_done() {
    //   const work = this.lineSteps.reduce((acc, curr) => acc + curr.computed_time, 0)
    //   return work
    // },

    getOperators() {
      const operators = new Set(
        this.lineStations.map((station) => station.operator)
      )
      return operators.size
    },

    isDesigningNewLine() {
      return this.currentLineType === 'design'
    },

    swctChartData() {
      if (!this.lineSteps.length) return []
      const currLineSteps = this.lineSteps
      const chartData = setSwctChartConfig(currLineSteps, this)
      return chartData
    }
  },

  actions: {
    clearAll() {

      this.$reset()
    },
    insertDummyLine() {
      this.lines.unshift(this.dummyListObject)
    },

    removeDummyLine() {
      this.lines = this.lines.filter((data) => data.id !== 0)
    },

    setTaktTime(time) {
      this.taktTime = time
    },

    setLineBalanceHistory(payload) {
      this.historyLineBalance = payload
    },

    setSelectedHistory(value) {
      this.selectedHistory = value
    },

    changeResultContentLoading() {
      this.resultContentLoading = !this.resultContentLoading
    },

    setSelectedLineIdToShowGraph(id) {
      this.selectedLineIdToShowGraph = id
    },

    getStationTotalAndValueAddTime(stationSteps) {
      return stationSteps.reduce(
        (acc, curr) => {
          acc[0] = acc[0] + curr.computed_time
          acc[1] = curr.value_type === 'Value-add' ? acc[1] + curr.computed_time : acc[1]
          return acc
        },
        [0, 0]
      )
    },

    async createNewLine(payload) {
      this.isCreatingNewLine = true
      const response = await LineBalanceService.createNewLine(payload)
      this.isCreatingNewLine = false
      return response
    },

    async getAllLines(params) {
      this.isFetchingAllLines = true
      const [error, data] = await LineBalanceService.getLines(params)
      this.isFetchingAllLines = false
      if (error) {
        console.log(error)
        return
      }
      const { results, count } = data
      this.totalLinesCount = count
      this.lines = results
    },

    async getParentLine(lineStudyId) {
      const [error, data] = await LineBalanceService.getParentLine(lineStudyId)
      if (error) {
        console.log(error)
        return
      }
      const { id, stations, balance_mode, mode_value, study } = data
      this.parentLineId = id
      this.lineStations = setStations(stations)
      this.lineDetails = study
      if (balance_mode === 'TAKT') this.taktTime = mode_value
    },

    async getLine(id) {
      const [error, data] = await LineBalanceService.getLine(id ? id : this.parentLineId)
      if (error) {
        console.log(error)
        return
      }
      this.lineStations = setStations(data.stations)
      if (data.balance_mode === 'TAKT') this.taktTime = data.mode_value
    },

    async updateLineStudy(payload) {
      console.log('update line payload', payload)
      this.updateLineLoding = true
      const [err] = await LineBalanceService.updateLineStudy(this.lineDetails.id, payload)
      this.updateLineLoding = false
      if (err) {
        console.log(err)
        return
      }
      this.lineDetails = { ...this.lineDetails, ...payload }

    },

    async getParentLineSteps() {
      const [err, data] = await LineBalanceService.getAllSteps(this.parentLineId)
      if (err) {
        console.log(err)
        return
      }
      const { stationIdToDictMap } = this
      // let steps = []
      // this.lineStations.forEach((station) => {
      //   const stationSteps = data.filter((step) => step.station === station.id)?.map((el) => ({
      //     ...el,
      //     piece_per_cycle: stationIdToDictMap[el.station]
      //       ? stationIdToDictMap[el.station].piece_per_cycle
      //       : 0,
      //     operator: stationIdToDictMap[el.station]
      //       ? stationIdToDictMap[el.station].operator
      //       : 0
      //   })).sort((a, b) => a.step_index - b.step_index)

      //   steps = [...steps, ...stationSteps]
      // })
      // this.parentLineSteps = steps
      this.parentLineSteps = data.map((el) => {
        const pcs_per_cycle = stationIdToDictMap[el.station] ? stationIdToDictMap[el.station].piece_per_cycle : 0
        return {
          ...el,
          piece_per_cycle: pcs_per_cycle,
          total_pcs_time: pcs_per_cycle ? parseFloat((el.computed_time * pcs_per_cycle).toFixed(2)) : 0,
          operator: stationIdToDictMap[el.station]
            ? stationIdToDictMap[el.station].operator
            : 0
        }
      })
    },

    async getAllLineSteps(id) {
      const [err, data] = await LineBalanceService.getAllSteps(id ? id : this.parentLineId)
      if (err) {
        console.log(err)
        return
      }
      // let steps = []
      // this.lineStations.forEach((station) => {
      //   const stationSteps = data.filter((step) => step.station === station.id)?.map((el) => ({
      //     ...el,
      //     piece_per_cycle: this.stationIdToDictMap[el.station]
      //       ? this.stationIdToDictMap[el.station].piece_per_cycle
      //       : 0,
      //     operator: this.stationIdToDictMap[el.station]
      //       ? this.stationIdToDictMap[el.station].operator
      //       : 0
      //   })).sort((a, b) => a.step_index - b.step_index)
      //   steps = [...steps, ...stationSteps]
      // })

      // this.lineSteps = steps
      this.lineSteps = data.map((el) => {
        const pcs_per_cycle = this.stationIdToDictMap[el.station]
          ? this.stationIdToDictMap[el.station].piece_per_cycle
          : 0

        return {
          ...el,
          piece_per_cycle: pcs_per_cycle,
          total_pcs_time: pcs_per_cycle ? parseFloat((el.computed_time * pcs_per_cycle).toFixed(2)) : 0,
          operator: this.stationIdToDictMap[el.station]
            ? this.stationIdToDictMap[el.station].operator
            : 0
        }
      })
      console.log('line steps ->', this.lineSteps)
    },

    async createNewStation(payload) {
      const [error, data] = await LineBalanceService.createNewStation(this.parentLineId, payload)
      if (error) {
        console.log(error)
        return
      }
      this.lineStations = [...this.lineStations, ...data]
    },

    async createNewSteps(stationId, payload) {
      const [error, data] = await LineBalanceService.createSteps(stationId, payload)
      if (error) {
        console.log(error)
        return []
      }
      return data
    },

    async appendNewSteps(stationId, payload) {
      const [error, data] = await LineBalanceService.appendSteps(stationId, payload)
      if (error) {
        console.log(error)
        return []
      }
      return data
    },

    async updateStep(id, payload) {
      const [error] = await LineBalanceService.updateSteps(id, payload)
      if (error) {
        console.log(error)
        return
      }
      // const temp = [...this.lineSteps]
      // const index = temp.findIndex((step) => step.id === id)
      // temp[index] = {
      //   ...temp[index],
      //   ...data
      // }
      // this.lineSteps = temp
    },

    async deleteStep(id) {
      const [error] = await StationService.deleteStep(id)
      if (error) {
        console.log(error)
        return
      }
      this.parentLineSteps = this.parentLineSteps
        .filter((step) => step.id !== id)
        .map((step) => {
          step.predecessor = step.predecessor.filter((el) => el !== id)
          return step
        })
    },

    async updateStation(id, payload) {
      const [error, data] = await LineBalanceService.updateStation(id, payload)
      if (error) {
        console.log(error)
        return
      }
      this.updateStationInList(id, data)
      // for new line
      // this.parentLineSteps = this.parentLineSteps.map((el) => {
      //   if (el.station !== id) return el
      //   return {
      //     ...el,
      //     piece_per_cycle: data.piece_per_cycle
      //   }
      // })
    },

    updateStationInList(id, obj) {
      const temp = [...this.lineStations]
      const index = temp.findIndex((el) => el.id === id)
      temp[index] = {
        ...temp[index],
        ...obj
      }
      this.lineStations = temp
    },

    async deleteStation(id) {
      const [error] = await LineBalanceService.deleteStation(id, { line: this.parentLineId })
      if (error) {
        console.log(error)
        return
      }
      // this.lineStations = this.lineStations.filter((el) => el.id !== id)
    },

    async getBalanceLine(id, payload = {}) {
      const [err, data] = await LineBalanceService.balanceLine(
        id ? id : this.parentLineId,
        payload
      )
      if (err) {
        console.log(err)
        return 0
      }
      this.taktTime = data['takt_time']
      this.balanceLineData = data
      return data
    },

    // setStationsAfterBalance(stations) {
    //   stations.forEach((station, key) => {
    //     let total_time = 0
    //     station.station_steps.forEach((stepId) => {
    //       const step = this.lineSteps.find((step) => step.id === stepId)
    //       if (step) {
    //         total_time += step.stopclock_time
    //         step.station = key
    //       }
    //     })setHistoryForUnbalanceLine
    //     station.total_time = total_time
    //     station.name = station.station_name
    //     station.id = key
    //   })
    //   console.log('line', stations)
    //   this.lineStations = stations
    // },

    setHistoryForUnbalanceLine() {
      this.historyLineBalance.push({
        label: 'Default',
        value: this.defaultLineId,
        parentLine: this.defaultLineId,
        mode: 'TAKT',
        mode_value: this.taktTime
      })
      this.selectedHistory = this.defaultLineId
    },

    async saveLineBalance(lineId, payload) {
      const [err, data] = await LineBalanceService.saveLineBalance(lineId, payload)
      if (err) {
        console.log(err)
        return 0
      }
      console.log(data)
      this.historyLineBalance.push({
        label: data.name,
        value: data.id,
        mode: data.balance_mode,
        parentLine: data.parent_line,
        mode_value: data.mode_value,
      })
      const id = data.parent_line
      await this.getLine(id)
      await this.getBalanceLine(id, { mode: data.balance_mode, value: data.mode_value })
      await this.getAllLineSteps(id)
      this.selectedHistory = data.id
      return 1
    },

    async getAllBalancedLines() {
      const [err, data] = await LineBalanceService.getAllSaveLines(this.parentLineId, {
        type: 'all_balanced_line'
      })
      if (err) {
        console.log(err)
        return
      }
      if (!data?.length) return
      data.forEach((line) => {
        this.historyLineBalance.push({
          label: line.name,
          value: line.id,
          // parentLine: line.parent_line.id,
          parentLine: line.parent_line,
          mode: line.balance_mode,
          mode_value: line.mode_value
        })
      })
    },

    async getAllDefaultLines() {
      const [err, data] = await LineBalanceService.getAllSaveLines(this.parentLineId)
      if (err) {
        console.log(err)
        return
      }
      this.defaultLines = data
      const unbalanceLine = this.defaultLines.reverse().find((line) => !line.name)
      this.defaultLineId = unbalanceLine?.id
    },

    async getBalanceRecommendations(params = {}) {
      const [err, data] = await LineBalanceService.getBalanceRecommendations(
        this.defaultLineId,
        params
      )
      if (err) {
        console.log('Err', err)
        return
      }
      this.recommendations = Object.keys(data).map((item, index) => {
        return {
          ...data[item],
          id: index,
          takt_time: item,
          stations_len: data[item].stations.length
        }
      })
    },

    async getAllStations() {
      const [err, data] = await LineBalanceService.getAllStations(this.parentLineId)
      if (err) {
        console.log(err)
        return
      }
      this.lineStations = setStations(data)
    },

    async addPredecessorsWithSteps(payload) {
      const [err] = await LineBalanceService.addStepPredecessors(payload)
      if (err) {
        console.log(err)
        return
      }
    },

    async updateStationsOrder(payload) {
      const [err] = await LineBalanceService.updateStationOrder(this.parentLineId, payload)
      if (err) {
        console.log(err)
        return
      }
    },

    async downloadCSVData(lineStudyId) {
      const payload = getCsvFormattedData(this)
      const [error, data] = await ExcelService.getLineBalanceSheet(lineStudyId, payload)
      if (error) {
        return 0
      }
      return data.url
    },

    async handleCloneLineStudy(lineId) {
      const [error] = await LineBalanceService.cloneLineStudy(lineId)
      if (error) {
        console.log(error)
        return 0
      }
      return 1
    },

    async updateStepsOrder(payload) {
      const [err] = await LineBalanceService.updateStepsOrder(payload)
      if (err) {
        console.log(err)
        return 0
      }
      return 1
    },
  }
})
