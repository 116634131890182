<template>
  <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
export default {
  props: ['title', 'data'],
  mounted() {
    this.setChartOptions()
  },
  data() {
    return {
      chartOptions: null
    }
  },
  methods: {
    setChartOptions() {
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          style: {
            padding: '1em'
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.title
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormatter: function () {
            const { y } = this.options
            return `<span style="font-weight: bold">${y}%</span>
            `
          }
        },

        plotOptions: {
          series: {
            size: '60%',
            innerSize: '60%',
            dataLabels: {
              alignTo: 'plotEdges',
              enabled: true,
              padding: 10,
              style: {
                fontWeight: 'bold',
                fontSize: '14px',
                width: '100%'
              }
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            data: this.data.map(([label, value]) => [label, Number(value.toFixed(1))])
          }
        ],
        exporting:{
          enabled:false
        }
      }
    }
  }
}
</script>
