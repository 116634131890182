<template>
  <div class="progress-container white-width" :style="{ width: whiteWidth + '% !important' }" />

  <a-dropdown placement="top" :trigger="isMarking ? [] : 'contextmenu'">
    <div
      class="progress-container marked-label"
      :style="{
        background: isOverlapped ? '#85cae0' : '#add8e6',
        width: interval.segment_end - interval.segment_start + '% !important'
      }"
    >
      <slot name="intervalName"></slot>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item v-if="contextMenuItems['add']" @click="$emit('addNewInterval')">
          {{ contextMenuItems['add'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['remove']" @click="$emit('removeInterval', intervalIndex)">
          {{ contextMenuItems['remove'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['editStart']" @click="clickEditCycle('start')">
          {{ contextMenuItems['editStart'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['editEnd']" @click="clickEditCycle('end')">
          {{ contextMenuItems['editEnd'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['rename']" @click="clickRenameCycle()">
          {{ contextMenuItems['rename'] }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
export default {
  name: 'CyclePainter',
  props: [
    'isMarking',
    'contextMenuItems',
    'interval',
    'prevEnd',
    'intervalIndex',
    'currentSliderPercent'
  ],
  emits: ['removeInterval', 'editInterval', 'addNewInterval','renameInterval'],
  computed: {
    whiteWidth() {
      return this.interval.segment_start - this.prevEnd <= 0
        ? this.interval.segment_start
        : this.interval.segment_start - this.prevEnd
    },
    isOverlapped() {
      const { segment_start, segment_end } = this.interval
      const { currentSliderPercent } = this
      return segment_start <= currentSliderPercent && currentSliderPercent <= segment_end
    }
  },
  methods: {
    clickEditCycle(position) {
      let editPoint = null
      if (position === 'start') {
        editPoint = this.interval.segment_start
      } else if (position === 'end') {
        editPoint = this.interval.segment_end
      }
      this.$emit('editInterval', editPoint, this.intervalIndex, position)
    },

    clickRenameCycle(){
      this.$emit('renameInterval', this.intervalIndex)
    }
  }
}
</script>
<style scoped>
.progress-container {
  height: 20px;
}

.marked-label {
  font-size: small;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
</style>
