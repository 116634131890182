export const getAuth = () => {
  const token = localStorage.getItem('token')
  return 'token ' + token
}
export const getAuthConfig = () => {
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: getAuth()
  }
  return { headers: header }
}
export const sessionAuthConfig = () => {
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: getAuth(),
    SessionKey: localStorage.getItem('sessionKey')
  }
  return { headers: header }
}

export const getConfig = () => {
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*'
  }
  return { headers: header }
}

export const getUploadConfig = (stateObj = {}, fileName = '') => {
  const header = {
    'Content-Type': 'multipart/form-data',
    Accept: '*/*',
    Authorization: getAuth()
  }

  const config = {
    headers: header,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      if (fileName && stateObj.updateItemProgress) {
        stateObj.updateItemProgress(fileName, percent)
      }
    }
  }
  return config
}
