export default [
  {
    value: 'left_upper_arm_angle',
    label: 'L Upper Arm'
  },
  {
    value: 'right_upper_arm_angle',
    label: 'R Upper Arm'
  },
  {
    value: 'left_lower_arm_angle',
    label: 'L Lower Arm'
  },
  {
    value: 'right_lower_arm_angle',
    label: 'R Lower Arm'
  },
  {
    value: 'neck_angle',
    label: 'Neck'
  },
  {
    value: 'trunk_bending_angles',
    label: 'Trunk'
  },
  {
    value: 'right_knee_angle',
    label: 'R Knee'
  },
  {
    value: 'left_knee_angle',
    label: 'L Knee'
  },
  // {
  //   value: 'legs_angle',
  //   label:'Leg'
  // },
  {
    value: 'left_wrist_angle',
    label: 'L Wrist'
  },
  {
    value: 'right_wrist_angle',
    label: 'R Wrist'
  }
]

export const jointChartConfig = {
  title: null,
  data: '',
  labels: '',
  xlabelString: 'Time in second(s)',
  ylabelString: 'Joint angle in degrees',
}

// konva drawing
export const jointsOptionConfig = [
  {
    value: 'final_neck_score',
    label: 'Neck'
  },
  {
    value: 'right_upper_arm_score',
    label: 'R Upper Arm'
  },
  {
    value: 'left_upper_arm_score',
    label: 'L Upper Arm'
  },
  {
    value: 'right_lower_arm_score',
    label: 'R Lower Arm'
  },
  {
    value: 'left_lower_arm_score',
    label: 'L Lower Arm'
  },
  {
    value: 'right_wrist_score',
    label: 'R Wrist'
  },
  {
    value: 'left_wrist_score',
    label: 'L Wrist'
  },
  {
    value: 'final_trunk_score',
    label: 'Trunk'
  },
  {
    value: 'right_leg_score',
    label: 'R Knee'
  },
  {
    value: 'left_leg_score',
    label: 'L Knee'
  },
]

export const leftRightJointsConfig = {
  'Right Upper Arm': {
    key: 'right_upper_arm',
    scoreAttribute: 'right_upper_arm_score',
    // angleAttribute: 'right_upper_arm_angle',
    // poseAttribute:'final_upper_arm_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 3,
    scores: ['reba', 'rula'],
  },
  'Left Upper Arm': {
    key: 'left_upper_arm',
    scoreAttribute: 'left_upper_arm_score',
    // angleAttribute: 'left_upper_arm_angle',
    // poseAttribute:'final_upper_arm_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 3,
    scores: ['reba', 'rula'],
  },
  'Right Lower Arm': {
    key: 'right_lower_arm',
    scoreAttribute: 'right_lower_arm_score',
    // angleAttribute: 'right_lower_arm_angle',
    // poseAttribute:'final_lower_arm_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 2: 'Low', 3: 'Medium', 4: 'High' },
    threshold: 2,
    scores: ['rula'],
  },
  'Left Lower Arm': {
    key: 'left_lower_arm',
    scoreAttribute: 'left_lower_arm_score',
    // angleAttribute: 'left_lower_arm_angle',
    // poseAttribute:'final_lower_arm_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 2: 'Low', 3: 'Medium', 4: 'High' },
    threshold: 2,
    scores: ['rula'],
  },
  'Right Wrist': {
    key: 'right_wrist',
    scoreAttribute: 'right_wrist_score',
    // angleAttribute: 'right_wrist_angle',
    // poseAttribute:'final_wrist_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba', 'rula'],
  },
  'Left Wrist': {
    key: 'left_wrist',
    scoreAttribute: 'left_wrist_score',
    // angleAttribute: 'left_wrist_angle',
    // poseAttribute:'final_wrist_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba', 'rula'],
  },
  Neck: {
    key: 'neck',
    scoreAttribute: 'final_neck_score',
    // angleAttribute: 'neck_angle',
    // poseAttribute:'final_neck_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba', 'rula'],
  },
  Trunk: {
    key: 'trunk',
    scoreAttribute: 'final_trunk_score',
    // angleAttribute: 'trunk_bending_angles',
    // poseAttribute:'final_trunk_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 3,
    scores: ['reba', 'rula'],
  },
  'Right Knee': {
    key: 'right_leg',
    scoreAttribute: 'right_leg_score',
    // angleAttribute: 'right_knee_angle',
    // poseAttribute:'final_knee_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba'],
  },
  'Left Knee': {
    key: 'left_leg',
    scoreAttribute: 'left_leg_score',
    // angleAttribute: 'left_knee_angle',
    // poseAttribute:'final_knee_score',
    reba_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rula_Thresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba'],
  },
};

export const jointsDict = {
  trunk: 'Trunk',
  upper_arm: 'Upper Arm',
  lower_arm: 'Lower Arm',
  wrist: 'Wrist',
  leg: 'Leg',
  neck: 'Neck'
}

export const RiskMap = {
  high: 'High',
  medium: 'Medium',
  low: 'Acceptable',
}

export const riskColorsMap = {
  Low: '#C9DBC2', // 006400b3
  // Medium: '#FFB9384C', // ffa500c7 Keeping it if needed in the future
  Medium: '#ffa500c7', // ffa500c7
  UpperMedium: '#ffff00',
  High: '#FF000066', // ff0000b3
  'Very High': '#FF000066',
};

export const riskColorsToARGBMapping = {
  C9DBC2: 'FFC9DBC2', // 006400b3
  // Medium: '#FFB9384C', // ffa500c7 Keeping it if needed in the future
  ffa500c7: 'FFFFFFCC', // ffa500c7
  // UpperMedium: '#ffff00',
  FF000066: 'FFFFCCCC', // ff0000b3
  // 'Very High': '#FF000066',
};

export const severityMap = {
  Low: '#006400B3',
  Medium: '#ffa500B3',
  UpperMedium: '#ffff00',
  High: '#ff0000B3',
  'Very High': '#ff0000B3',
}

export const jointsPlotLineThresholds = {
  left_upper_arm_angle: [45],
  right_upper_arm_angle: [45],
  left_lower_arm_angle: [60, 100],
  right_lower_arm_angle: [60, 100],
  neck_angle: [20],
  trunk_bending_angles: [20],
  right_knee_angle: [60],
  left_knee_angle: [60],
  left_wrist_angle: [15],
  right_wrist_angle: [15]
}

export const loadAnalysisChartConfig = {
  title: 'Load Analysis Chart',
  data: [],
  labels: [],
  xlabelString: 'Timestamp in seconds',
  ylabelString: 'Activity',
};

export const lineChartParams = [
  {
    title: 'Risk Occurrence',
    description:
      'Risk occurrence refers to the count of intervals during which the limb angle exceeds the safe limit.',
    key: 'risk_occurence',
    demoValue: '2',
    unit: false
  },
  {
    title: 'Total Duration',
    description:
      'The total amount of time where the angle of the limb exceeds safe limit, indicating a period of potential risk for injury.',
    key: 'total_duration',
    demoValue: '4s',
    unit: true
  },
  {
    title: 'Average Duration',
    description:
      'The mean duration of time representing the average period of potential risk within this task.',
    key: 'avg_duration',
    demoValue: '2s',
    unit: true
  },
  {
    title: 'Average Recovery',
    description:
      'Average duration during which a limb remains within safe limits between two periods of risk.',
    key: 'avg_recovery',
    demoValue: '5s',
    unit: true
  }
]