<template>
  <div class="main-container">
    <div class="cycles-head-container mb-1">
      <a-space>
        <a-typography-title :level="4" class="mb-0">Edit Step Timings</a-typography-title>
      </a-space>

      <a-space class="d-flex align-items-center">
        <a-button
          class="d-flex align-items-center"
          :disabled="!isStepTimeUpdate"
          @click="handleReset"
        >
          <template #icon>
            <UndoOutlined />
          </template>
          Reset Step Timings
        </a-button>
        <a-button class="d-flex align-items-center" :loading="updateLoading" @click="handleDone">
          <template #icon>
            <CheckOutlined v-if="isStepTimeUpdate" />
            <ShrinkOutlined v-else />
          </template>
          {{ isStepTimeUpdate ? 'Done' : 'Exit' }}
        </a-button>
      </a-space>
    </div>

    <div class="cycle-video-container">
      <video
        class="cycle-video-player"
        ref="video"
        muted
        crossorigin="anonymous"
        disablePictureInPicture
        @playing="isPlaying = true"
        @loadedmetadata="getCycleVideoTime()"
        @timeupdate="handleGetCurrentTime"
      >
        <source :src="videoUrl" :key="videoUrl" />
      </video>
    </div>

    <div class="timeline-container" ref="timelineContainer">
      <div class="d-flex justify-content-between align-items-center">
        <!-- start time -->
        <span class="position-relative">
          <small class="me-1">{{ getFormattedTime(currentTime) }}</small>
          <small v-if="isStepMarking || editStep" class="m-0" style="color: #dc3545"
            >*Selected</small
          >
        </span>

        <small>
          <PauseOutlined style="font-size: 20px" v-if="isPlaying" @click="togglePlaying" />
          <CaretRightOutlined style="font-size: 20px" v-else @click="togglePlaying" />
        </small>

        <!-- end time -->
        <small>{{ getFormattedTime(endCycleTime) }}</small>
      </div>

      <a-slider
        v-model:value="currentFrame"
        :min="0"
        :max="totalFrames"
        :tipFormatter="(val) => formatFrame(val)"
        id="annotate-slider"
        class="mx-0 mt-1"
        @change="handleVideoPause"
      />

      <div
        :style="{ height: '20px', width: '100%', position: 'relative' }"
        class="m-0 border d-flex"
      >
        <!-- vertical bar to represent current time -->
        <div class="hairline" :style="cursorStyle">
          <div class="hairline-top"></div>
        </div>

        <painter
          v-for="(interval, index) in steps"
          :key="index"
          :isMarking="isStepMarking || editStep"
          :contextMenuItems="stepsMenuItem"
          :interval="interval"
          :intervalIndex="index"
          :current-slider-percent="currentSliderPercent"
          :prevEnd="getPrevEnd(index)"
          @editInterval="handleEditStep"
          @removeInterval="handleRemoveStep"
          @addNewInterval="handleDivideAtTimestamp"
        >
          <template #intervalName>
            <a-tooltip :title="interval.name">
              {{ interval.name }}
            </a-tooltip>
          </template>
        </painter>
      </div>
    </div>
  </div>
</template>
<script>
import {
  UndoOutlined,
  PauseOutlined,
  ShrinkOutlined,
  CaretRightOutlined,
  CheckOutlined
} from '@ant-design/icons-vue'
import { colors, stepsMenuItem } from '../config'
import { getFormattedTime } from 'src/utils/outline'
import { mapActions, mapState } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useClassificationStore } from 'src/stores/classification'
import { useHandTrackingStore } from 'src/stores/handTracking'
import { useSegmentMapingStore } from 'src/stores/segmentMapping'
import { useSegmentationStore } from 'src/stores/segmentation'
import { getPostureIndexName } from 'src/utils/ergonomics'
import { useToast } from 'vue-toastification'
import Painter from './Painter.vue'

const toast = useToast()

export default {
  props: ['videoUrl'],
  emits: ['close'],
  components: {
    UndoOutlined,
    PauseOutlined,
    ShrinkOutlined,
    CaretRightOutlined,
    CheckOutlined,
    Painter
  },
  setup: () => ({
    colors,
    stepsMenuItem,
    getFormattedTime
  }),

  data() {
    return {
      steps: [],
      stepIndex: null,
      isStepMarking: false,
      isPlaying: false,
      currentTime: 0,
      prevFrames: null,
      initCycleTime: null,
      endCycleTime: null,
      cycleDuration: null,
      videoPlayInterval: null,
      updateLoading: false,
      stepIdToObjMap: {},
      isMerged: false,
      isVideoLoaded: false,
      // Slider
      currentFrame: 0,
      currentSliderPercent: 0,
      startingTime: null,
      editStep: false,
      editPosition: null
    }
  },

  computed: {
    ...mapState(useSegmentationStore, ['stepsDataForEditTimings']),
    ...mapState(useStationStore, [
      'newStudy',
      'masterCycle',
      'studyFilesObject',
      'masterCycleVideoId'
    ]),

    videoFPS() {
      if (!this.masterCycleVideoId || !this.studyFilesObject) return
      const fps = this.studyFilesObject[this.masterCycleVideoId]?.fps
      return fps || 30
    },

    cursorStyle() {
      return { left: this.currentSliderPercent + '%' }
    },

    indexToTitleMap() {
      const temp = {}
      this.steps.forEach((step) => {
        temp[step.step_index] = !step.name ? `step ${Number(step.step_index) + 1}` : step.name
      })
      return temp
    },

    isSegmentChanged() {
      return JSON.stringify(this.steps) !== JSON.stringify(Object.values(this.stepIdToObjMap))
    },

    isStepTimeUpdate() {
      if (!this.stepsDataForEditTimings.length) return false
      return (
        this.steps.length !== Object.values(this.stepsDataForEditTimings).length ||
        this.steps.some((step) => {
          if (!step.id) return true
          const prev = this.stepIdToObjMap[step.id]
          return step.segment_start !== prev.segment_start || step.segment_end !== prev.segment_end
        })
      )
    },

    totalFrames() {
      if (!this.stepsDataForEditTimings?.length) return 0
      const segments = this.stepsDataForEditTimings
      const first = segments[0]
      const end = segments.at(-1)
      const diff = end.segment_end - first.segment_start
      return diff
    }
  },

  watch: {
    currentFrame(frame) {
      if (this.isPlaying) this.currentSliderPercent = this.getPercent(frame)
      else this.updateVideoTime()

      this.editStep ? this.editStepPaint() : this.handlePainterChange(frame)
    },

    totalFrames() {
      this.setExistingStepIntervals()
    }
  },

  methods: {
    ...mapActions(useSegmentationStore, ['createSegments', 'updateSegment']),
    ...mapActions(useHandTrackingStore, ['generateHandTrackingResult']),
    ...mapActions(useClassificationStore, ['assignTherbligFromStepName']),
    ...mapActions(useSegmentMapingStore, ['startSegmentRemaping', 'startSegmentationMapping']),
    ...mapActions(useStationStore, ['updateStudy', 'fetchStudy']),

    formatFrame(frame) {
      return `Frame id: ${frame}`
    },

    getFrame(percent) {
      if (percent === undefined) return 0
      return Math.ceil((percent * this.totalFrames) / 100)
    },

    getPercent(frame) {
      return (frame / this.totalFrames) * 100
    },

    isOverlapped(currentStart) {
      return this.steps.some(
        ({ segment_start: start, segment_end: end }) => start <= currentStart && currentStart <= end
      )
    },

    getPrevEnd(index) {
      // return start for 0th index else end of the segment
      return index - 1 < 0 ? this.steps[index].segment_start : this.steps[index - 1].segment_end
    },

    handleGetCurrentTime(event) {
      this.currentTime = event.target.currentTime
      this.currentFrame = this.currentTime * this.videoFPS - this.prevFrames
    },

    setStepIdToObjectMap() {
      if (!this.steps?.length) return
      this.stepIdToObjMap = this.steps.reduce((res, el) => {
        res[el.id] = { ...el }
        return res
      }, {})
    },

    updateVideoTime() {
      const video = this.$refs.video
      if (video) {
        this.currentTime = (this.prevFrames + this.currentFrame) / this.videoFPS
        video.currentTime = this.currentTime
        this.currentSliderPercent = this.getPercent(this.currentFrame)
      }
    },

    getCycleVideoTime() {
      const segments = this.stepsDataForEditTimings
      const first = segments[0]
      const end = segments.at(-1)

      this.initCycleTime = first.segment_start / this.videoFPS
      this.endCycleTime = end.segment_end / this.videoFPS
      this.prevFrames = first.segment_start > 0 ? first.segment_start - 1 : 0
      this.cycleDuration = Math.floor(end.segment_end - first.segment_start) / this.videoFPS
      this.currentTime = this.initCycleTime
      if (this.$refs.video) {
        this.$refs.video.currentTime = this.currentTime
        this.$refs.video.pause()
      }
      this.setExistingStepIntervals()
      this.isPlaying = false
      this.isVideoLoaded = true
    },

    setExistingStepIntervals() {
      let defaultIntervals = []
      const segments = this.stepsDataForEditTimings
      if (!this.totalFrames) return // for getting percent value
      console.log('check s/e: ', segments, this.masterCycle)
      console.log('prev frames:', this.prevFrames)

      segments.forEach((segment, index) => {
        const newStart =
          segment['segment_start'] === segments[index - 1]?.segment_end
            ? segment['segment_start'] + 1
            : segment['segment_start']

        const end = this.getPercent(segment['segment_end'] - this.prevFrames)
        defaultIntervals.push({
          ...segment,
          segment_start: this.getPercent(newStart - this.prevFrames),
          segment_end: end,
          parentStep: segment.name
        })
      })
      this.steps = defaultIntervals.sort((a, b) => a.segment_start - b.segment_start)
      this.setStepIdToObjectMap()
    },

    async togglePlaying() {
      if (!this.isVideoLoaded || !this.cycleDuration) return

      if (this.isPlaying && !this.$refs.video?.paused) {
        this.handleVideoPause()
      } else if (!this.isPlaying) {
        await this.$refs.video.play()
        this.videoPlayInterval = setInterval(this.videoTimeUpdate, 100 / this.videoFPS)
      }
    },

    handleVideoPause() {
      if (!this.$refs.video || this.$refs.video.paused) return
      else this.$refs.video.pause()
      this.isPlaying = false
      clearInterval(this.videoPlayInterval)
    },

    videoTimeUpdate() {
      if (this.currentTime >= this.endCycleTime) {
        if (!this.$refs.video.paused) this.$refs.video.pause()
        this.isPlaying = false
        this.$refs.video.currentTime = this.initCycleTime
        clearInterval(this.videoPlayInterval)
      }
    },

    // Slider /  Step Marking
    handleKeyDownEvents(evt) {
      if (evt.code === 'Space') {
        evt.preventDefault()
        if (this.editStep) {
          if (!this.validStepDuration()) return
          this.resetEditStep()
        } else if (this.isStepMarking) {
          this.handleMarkStepEnd()
        } else if (!this.isStepMarking) {
          this.handleVideoPause()
          this.handleMarkStepStart()
        }
      }
    },

    setStepIndexToMark(currentSliderPercent) {
      const steps = this.steps.sort((a, b) => a.segment_start - b.segment_start)
      for (let idx = 0; idx < steps.length; idx++) {
        const { segment_start } = steps[idx]
        if (currentSliderPercent < segment_start) {
          this.stepIndex = idx
          return
        }
      }
      this.stepIndex = this.steps.length
    },

    getStepsData(neighbour = 'next') {
      const temp = [...this.steps]
      const index = this.stepIndex
      const currentStep = temp[index]
      let neighbourStep = null
      if (neighbour === 'next') neighbourStep = temp[index + 1]
      else if (neighbour === 'previous') neighbourStep = temp[index - 1]

      return {
        temp,
        index,
        currentStep,
        neighbourStep
      }
    },

    validStepDuration(multiple = false) {
      const intv = [...this.steps]
      if (multiple) {
        let isValid = false
        isValid = intv.some((c) => {
          const start = this.getFrame(c.segment_start)
          const end = this.getFrame(c.segment_end)
          return end - start > 0 && end - start <= this.videoFPS
        })
        return isValid
      }
      const start = this.getFrame(intv[this.stepIndex].segment_start)
      const end = this.getFrame(intv[this.stepIndex].segment_end)
      if (end - start > 0 && end - start <= this.videoFPS) {
        toast.info('Step interval should not be less than 1 second!')
        return false
      }
      return true
    },

    updateStepsIndexesInList(stepIndex) {
      const temp = [...this.steps]
      for (let i = stepIndex; i < temp.length; i++) {
        temp[i] = {
          ...temp[i],
          step_index: i
        }
      }
      this.steps = temp
    },

    handleMarkStepStart() {
      this.startingTime = this.currentFrame
      const currentSliderPercent = this.getPercent(this.currentFrame)
      if (this.isOverlapped(currentSliderPercent)) {
        toast.error('Steps can not overlapped!')
        return
      }
      this.isStepMarking = true
      this.setStepIndexToMark(currentSliderPercent)
      const obj = {
        step_index: this.stepIndex,
        segment_start: currentSliderPercent,
        segment_end: currentSliderPercent,
        name: `Step ${this.stepIndex + 1}`,
        parentStep: `Step ${this.stepIndex + 1}`
      }
      let temp = [...this.steps, obj].sort((a, b) => a.segment_start - b.segment_start)
      this.steps = temp
    },

    handlePainterChange(currentSliderValue) {
      if (!this.isStepMarking) return
      if (currentSliderValue <= this.startingTime && this.steps.length > 0) return
      const end = this.getPercent(currentSliderValue)
      const temp = [...this.steps]
      const nextStepStart = temp[this.stepIndex + 1]?.segment_start
      if (nextStepStart && end >= nextStepStart) return
      temp[this.stepIndex].segment_end = end
      this.steps = temp
    },

    handleMarkStepEnd() {
      if (!this.isStepMarking) return

      const intv = [...this.steps]
      const start = this.getFrame(intv[this.stepIndex].segment_start)
      const end = this.getFrame(intv[this.stepIndex].segment_end)
      if (end === start) this.steps.splice(this.stepIndex, 1)

      if (end - start > 0 && end - start <= this.videoFPS) {
        return toast.info('Step interval should not be less than 1 second!')
      }
      this.updateStepsIndexesInList(this.stepIndex)
      this.stepIndex = null
      this.startingTime = null
      this.isStepMarking = false
    },

    handleRemoveStep(stepIndex) {
      // const cycleId = this.steps[stepIndex]?.id
      // if (cycleId) this.deleteCyclesIds.add(cycleId)
      this.steps.splice(stepIndex, 1)
      this.updateStepsIndexesInList(stepIndex)
      this.stepIndex = null
      this.startingTime = null
      this.isStepMarking = false
      this.editStep = false
      this.editPosition = null
      if (!this.steps.length) {
        this.currentFrame = 0
      }
    },

    handleEditStep(editValue, editStepIndex, position) {
      if (this.editStep) this.resetEditStep()
      this.editStep = true
      this.stepIndex = editStepIndex
      this.currentFrame = this.getFrame(editValue)
      this.editPosition = position
      this.handleVideoPause()
    },

    resetEditStep() {
      this.handleVideoPause()
      this.editStep = false
      this.stepIndex = null
      this.editPosition = null
    },

    editStepPaint() {
      if (this.stepIndex < 0) return
      this.editPosition === 'start'
        ? this.editStepStart()
        : this.editPosition === 'end' && this.editStepEnd()
    },

    editStepStart() {
      const { temp, currentStep, neighbourStep: prevStep } = this.getStepsData('previous')

      const newStart = this.getPercent(this.currentFrame)
      // return if start is greater then end
      if (currentStep && currentStep.segment_end < newStart) return
      // return if start is merged with the previous label start
      if ((prevStep && newStart <= prevStep.segment_end) || !currentStep) {
        if (!this.isMerged) toast.info('Two steps can not be merged!')
        this.isMerged = true
        return
      }
      this.isMerged = false
      temp[this.stepIndex].segment_start = newStart
      this.steps = temp
    },

    editStepEnd() {
      const { temp, currentStep, neighbourStep: nextStep } = this.getStepsData('next')

      const newEnd = this.getPercent(this.currentFrame)
      // return if end is greater then start
      if (currentStep && currentStep.segment_start > newEnd) return
      // return if end is merged with the next label start
      if ((nextStep && newEnd >= nextStep.segment_start) || !currentStep) {
        if (!this.isMerged) toast.info('Two steps can not be merged!')
        this.isMerged = true
        return
      }
      this.isMerged = false
      temp[this.stepIndex].segment_end = newEnd
      this.steps = temp
    },

    handleDivideAtTimestamp() {
      const temp = [...this.steps]
      const stepIndex = temp.findIndex((step) => {
        const { segment_start, segment_end } = step
        return segment_start < this.currentSliderPercent && this.currentSliderPercent < segment_end
      })
      if (stepIndex < 0) return
      const currentSliderPercent = this.getPercent(this.currentFrame)
      const firstStep = temp[stepIndex]

      const start = this.getFrame(firstStep.segment_start)
      const curr = this.getFrame(currentSliderPercent)
      const end = this.getFrame(firstStep.segment_end)

      if (end - curr <= this.videoFPS || curr - start <= this.videoFPS) {
        return toast.info('Step interval should not be less than 1 second!')
      }

      const newStart = this.getFrame(currentSliderPercent) + 1
      const prevChild = temp.filter((step) => step.parentStep === firstStep.parentStep)
      const newStep = {
        parentStep: firstStep.parentStep,
        step_index: stepIndex + 1,
        segment_start: this.getPercent(newStart),
        segment_end: firstStep.segment_end,
        name: `${firstStep.parentStep} ${getPostureIndexName(prevChild.length - 1)}`
      }

      temp[stepIndex] = {
        ...firstStep,
        segment_start: firstStep.segment_start,
        segment_end: currentSliderPercent
      }

      for (let i = stepIndex + 1; i < temp.length; i++) {
        temp[i] = {
          ...temp[i],
          step_index: temp[i].step_index < stepIndex ? temp[i].step_index : temp[i].step_index + 1,
          name: temp[i]?.name || `Step ${temp[i].step_index + 1}`
        }
      }

      temp.splice(stepIndex + 1, 0, newStep)
      this.steps = temp
    },

    async handleDone() {
      if (!this.isStepTimeUpdate) {
        this.$emit('close', this.isSegmentChanged ? true : false)
        return
      }
      const { cycle_start_frame_no, cycle_end_frame_no } = this.masterCycle

      let updatedSteps = []
      this.steps.forEach((step, index, arr) => {
        const { step_index, segment_start, segment_end, name } = step
        let newStart = this.prevFrames + this.getFrame(segment_start)
        let newEnd = this.prevFrames + this.getFrame(segment_end)
        const prevEnd = updatedSteps?.at(-1) ? updatedSteps.at(-1).segment_end : null

        const nextStart = arr[index + 1]
          ? this.prevFrames + this.getFrame(arr[index + 1].segment_start)
          : null

        newStart = prevEnd && newStart <= prevEnd ? prevEnd + 1 : newStart
        newEnd = nextStart && newEnd >= nextStart ? nextStart - 1 : newEnd
        updatedSteps.push({
          id: step?.id,
          step_index,
          name,
          segment_start: Math.max(Number(cycle_start_frame_no), newStart),
          segment_end: Math.min(Number(cycle_end_frame_no) - 1, newEnd)
        })
      })
      console.log('edit step timing payload: ', updatedSteps)
      try {
        this.updateLoading = true
        const res = await this.createSegments(updatedSteps)
        if (!res) return
        if (this.newStudy.get_improvement && this.newStudy?.station?.no_of_work_regions >= 1) {
          await this.generateHandTrackingResult()
        }
        await this.startSegmentRemaping()
        await this.updateStudy({ no_of_steps: updatedSteps.length })
      } catch (error) {
        console.log('error is update step timing:', error)
      } finally {
        this.updateLoading = false
        this.$emit('close')
      }
    },

    computeTimelineWidth() {
      if (this.$refs.timeline) {
        this.timelineWidth = parseInt(this.$refs.timeline.getBoundingClientRect().width, 10)
      }
    },

    handleReset() {
      this.currentTime = 0
      this.isStepMarking = false
      this.editStep = false
      this.getCycleVideoTime()
    }
  },

  created() {
    window.addEventListener('resize', this.computeTimelineWidth)
  },

  mounted() {
    this.handleReset()
    this.cycleDuration = null
    // this.setStepIdToObjectMap()
    this.computeTimelineWidth()
    document.addEventListener('keydown', this.handleKeyDownEvents)
  },

  beforeUnmount() {
    // this.$refs.video.currentTime = 0
    this.stepIdToObjMap = {}
    if (!this.$refs.video.paused) this.$refs.video.pause()
    window.removeEventListener('resize', this.computeTimelineWidth)
    document.removeEventListener('keydown', this.handleKeyDownEvents)
  }
}
</script>
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.7em;
  overflow: hidden;
}

.cycles-head-container {
  display: flex;
  justify-content: space-between;
}

.cycle-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.cycle-video-player {
  position: absolute;
  height: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.control-container {
  display: flex;
  padding: 0 0.7em;
}

.timeline-container {
  height: 80px;
  border: 1px solid lightgray;
  padding: 0 1em;
  position: relative;
  overflow: hidden;
}

.hairline {
  height: 100%;
  width: 1px;
  background: black;
  position: relative;
  z-index: 1;
  transition: left 0ms ease-out;
}

.hairline-top {
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translate(-45%, 0%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid black;
}

.timeline {
  height: 3px;
  width: 100%;
  background: lightgray;
  position: relative;
}

.point {
  height: 20px;
  width: 20px;
  background: gray;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

.selected-point {
  width: 30px;
  height: 30px;
  background: transparent;
  border: 1px solid;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.step-label {
  font-size: small;
  position: absolute;
  /* top: 30%; */
  left: 0%;
  overflow: hidden;
  /* left: 100%; */
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
  cursor: default;
  z-index: 9;
}

.selected-step {
  position: absolute;
  height: 35px;
  border: 1px solid;
  top: 50%;
  transform: translate(0%, -50%);
}

.step-timeline {
  color: #000;
  height: 20px;
}
</style>
