import { defineStore } from 'pinia'
import AuthService from 'src/services/auth'
import { roles } from 'src/config/roles-config.js'
import { useToast } from 'vue-toastification'
import router from 'src/router'
import { PublicClientApplication } from '@azure/msal-browser'
import { isUserAssignedToOrg } from 'src/utils/auth'
import { handleError, customErrorHandler } from 'src/utils/error'
import { clearLocalStorageExcept } from 'src/config/auth-config'
import { useLineBalanceStore } from './lineBalancing.js'


const toast = useToast()

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isSidebarOpen: true,
    user: localStorage.getItem('name'),
    token: localStorage.getItem('token'),
    refresh: null,
    isTokenSet: !!localStorage.getItem('token'),
    organization: localStorage.getItem('organization'),
    role: localStorage.getItem('role'),
    hostURL: null,
    poc: null,
    isPolicyAccepted: null,
    accessToken: null,
    msalInstance: null,
    azureLogin: null,
    isOtpSetup: null,
    email: localStorage.getItem('email'),
    require_otp: null,
    isSigningIn: false,
    AWSCustomerIdentifier: null,
    sessionKey: ''
  }),
  getters: {
    // user: (state) => state.user,
    // token: (state) => state.token,
    // role: (state) => state.role,
    // organization: (state) => state.organization,
    // isPolicyAccepted: (state) => state.isPolicyAccepted,
    // email: (state) => state.email,
    // isTokenSet: (state) => state.isTokenSet,
    // refresh: (state) => state.refresh,
    // hostURL: (state) => state.hostURL,
    // poc: (state) => state.poc,
    // accessToken: (state) => state.accessToken,
    // msalInstance: (state) => state.msalInstance
    // azureLogin: (state) => state.azureLogin,
    // isOtpSetup: (state) => state.isOtpSetup,
    // require_otp: (state) => state.require_otp
  },
  actions: {
    clearLineBalanceState() {
      const lineBalanceStore = useLineBalanceStore()
      lineBalanceStore.clearAll()
    },
    setUser(value) {
      this.user = value
    },
    setToken(value) {
      this.token = value
      this.isTokenSet = value ? true : false
    },
    setOrganization(value) {
      this.organization = value
    },
    setRole(value) {
      this.role = value
    },
    setIsPolicyAccepted(value) {
      this.isPolicyAccepted = value
    },
    setEmail(value) {
      this.email = value
    },
    setIsSidebarOpen(value) {
      this.isSidebarOpen = value
    },
    setMsalInstance(value) {
      this.msalInstance = value
    },
    setAWSCustomerIdentifier(value) {
      this.AWSCustomerIdentifier = value
    },
    setSessionKey(value) {
      this.sessionKey = value
    },

    async createMsalInstance() {
      const msalConfig = {
        auth: {
          clientId: 'c5093c91-21ae-4374-9be1-d95791ea78eb',
          authority: 'https://login.microsoftonline.com/organizations'
        },
        cache: {
          cacheLocation: 'localStorage'
        }
      }
      this.msalInstance = new PublicClientApplication(msalConfig)
    },
    async azureSignIn(payload) {
      const [error, data] = await AuthService.azureSignIn(payload)
      if (error) {
        toast.error(error.response.data.response)
        this.clearAllStates()
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('isOtpSetup', false)
        localStorage.setItem('require_otp', true)
        return
      }

      if (data.response === 'User created successfully') {
        setTimeout(toast.success(data.response), 3000)
        this.clearAllStates()
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('isOtpSetup', false)
        localStorage.setItem('require_otp', true)
        return
      } else {
        if (await isUserAssignedToOrg(data))
          return toast.error('User is not assigned to any organization')
      }
      await this.setUserDetails({ ...data, email: payload.username })
      router.replace({ path: '/' })
    },

    async userLogin(payload) {
      this.isSigningIn = true
      const [error, data] = await AuthService.signIn(payload)
      this.isSigningIn = false
      if (error) {
        handleError(error?.response)
        return false
      }
      const { aws_customer_identifier } = data
      if (!aws_customer_identifier) {
        if (await isUserAssignedToOrg(data)) {
          if (error?.response?.status === 429) {
            return toast.error('Too many attempts.Please try after 1 min.')
          }
          return handleError(error?.response)
        }
      }
      await this.setUserDetails({ ...data, email: payload.username })
      this.azureLogin = false
      return true
    },

    setUserDetails(data) {
      const {
        id,
        name,
        email,
        role,
        token,
        Organization,
        poc,
        is_policy_accepted,
        is_otp_verified,
        refresh,
        session_key,
        aws_customer_identifier
      } = data
      const username = role === roles.admin ? 'Andrey Konin' : name
      const org = role === roles.admin ? 'ADMINISTRATOR' : Organization

      const currentTime = new Date()
      window.localStorage.setItem('id', id)
      window.localStorage.setItem('name', username)
      window.localStorage.setItem('email', email)
      window.localStorage.setItem('role', role)
      window.localStorage.setItem('organization', org)
      window.localStorage.setItem('token', token)
      window.localStorage.setItem('refresh', refresh)
      window.localStorage.setItem('timestamp', currentTime)
      window.localStorage.setItem('poc', poc)
      window.localStorage.setItem('isPolicyAccepted', is_policy_accepted)

      window.localStorage.setItem('require_otp', false)
      window.localStorage.setItem('isOtpSetup', is_otp_verified)
      window.localStorage.setItem('sessionKey', session_key)
      window.localStorage.setItem('AWSCustomerIdentifier', aws_customer_identifier)

      this.setUser(username)
      this.setEmail(email)
      this.setToken(token)
      this.setOrganization(org)
      this.setRole(role)
      this.setIsPolicyAccepted(is_policy_accepted)
      this.setAWSCustomerIdentifier(aws_customer_identifier)
      this.setSessionKey(session_key)
    },

    clearState() {
      if (this.isTokenSet) {
        AuthService.logout()
      }
      this.clearAllStates()
      // localStorage.clear()
      sessionStorage.clear()
      // localStorage.setItem('isOtpSetup', false)
      // localStorage.setItem('require_otp', true)
      router.replace({ name: 'Login' })
    },

    clearAllStates() {
      this.setUser(null)
      this.setEmail(null)
      this.setToken(null)
      this.setOrganization(null)
      this.setRole(null)
      this.setIsPolicyAccepted(null)
      this.setSessionKey(null)
      this.setAWSCustomerIdentifier(null)
    },

    userLogout() {
      if (this.isTokenSet) {
        AuthService.logout()
      }
      //  clear all keys from localStorage except that keep track for Demo functionality 
      this.clearAllStates()
      clearLocalStorageExcept()
      this.clearLineBalanceState()
      sessionStorage.clear()
    },

    async forgotPassword(payload) {
      const [error, data] = await AuthService.forgotPassword(payload)
      if (error) return customErrorHandler(error?.response)

      toast.success(data.response)
      router.replace({ name: 'Login' }, 3000)
    },

    async changePassword(payload) {
      const [error, data] = await AuthService.changePassword(payload)
      if (error) return customErrorHandler(error?.response)

      toast.success(data.response)
      router.replace({ name: 'Login' }, 3000)
    }
  }
})
