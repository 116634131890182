export const CDN_URL = import.meta.env.VITE_CDN_URL || ''

export const ASSETS = {
  BACKGROUND_IMAGE: `${CDN_URL}/background.png`,
  PMTS: {
    MODAPTS: {
      M1: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_01_Calculator.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_02_Trigger.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_03_TurnDownNut.png`
      },
      M2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_04_TurnKnob.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_05_TurnOver.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_06_WalkGrab.png`
      },
      M3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_07_MovePencil.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_08_Move_Divider.png`
      },
      M4: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_09_Move_wo_body.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_10_Move_w_body.png`
      },
      M5: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_11_To_L_R.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_12_Up.png`
      },
      M7: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_13_M7_Move.png`
      },
      G0: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_14_TouchButton.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_15_Hand_on_Box.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_16_Palm_on_Paper.png`
      },
      G1: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_17_Fingers_Marble.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_18_Fingers_wrench.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_19_Drawer_Pull.png`
      },
      G3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_20_Get_Small_Obj.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_21_Get_Flat_Obj.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_22_Get_Jumbled.png`
      },
      P0: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_23_Toss_into_Box.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_24_Return Tool.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_25_TransferBrush.png`
      },
      P2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_26_Locate Pencil.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_27_Register Philips.png`
      },
      P5: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_28_Register_Turn.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_29_Thread_Nut.png`
      },
      J2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_30_Roll_Nut.png`
      },
      X4: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_31_Extra_Force.png`
      },
      W5: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_32_Walk_Restricted.png`
      },
      F3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_33_Foot_Pedal.png`
      },
      B17: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_34_Bend_Arise.png`
      },
      S30: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_35_Sitting_Chair.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_36_PushBack_Chair.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_37_Rising_Chair.png`,
        4: `${CDN_URL}/PMTS/MODAPTS/MOD_38_Pulling_Chair.png`
      },
      C3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_39_Turn_Crank.png`
      },
      USE: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_40_Hammer.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_41_Brush_Debris.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_42_Not_Drill.png`
      },
      E2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_43_Eye Fixation.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_44_Eye Travel.png`
      },
      E4: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_45_Eye_Focus.png`
      },
      HAND: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_46_Write.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_47_PRINT.png`
      },
      LOAD: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_48_Load_1_Hand.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_49_Load_2_Hands.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_50_slide.png`
      },
      DECIDE: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_51_D3_Decision.png`
      },
      COUNT: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_52_N3_Counting.png`
      }
    }
  },
  LOGO: `${CDN_URL}path/to/your/logo.png`,
  ICON: `${CDN_URL}path/to/your/icon.png`
}
