import { defineStore } from 'pinia'
import { useStationStore } from './station'
import ClassificationService from 'src/services/classification'
import { sortElements } from 'src/components/User/StationBalancing/config'


export const useClassificationStore = defineStore('classification', {
  state: () => ({
    therbligs: [],
    actionNameToDescMap: {}
  }),

  getters: {
    actions(state) {
      // return state.therbligs.map(({ id, name }) => ({ value: id, label: name }))
      return state.therbligs
        .map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        }))
        .sort((a, b) => a.order - b.order)
    },

    getQuestions(state) {
      return (actionIds) => state.therbligs.filter((action) => actionIds.includes(action.id))
    },

    idToActionName(state) {
      return state.therbligs.reduce((res, action) => {
        res[action.id] = action.name
        return res
      }, {})
    },

    idToActionObj(state) {
      return state.therbligs.reduce((res, action) => {
        res[action.id] = action
        return res
      }, {})
    }
  },

  actions: {
    async startClassification() {
      const { newStudyId: studyId } = useStationStore()
      console.log('classification req')
      return await ClassificationService.startClassification(studyId)
    },

    async assignTherbligsToSegments() {
      const { newStudyId: studyId } = useStationStore()
      const [error] = await ClassificationService.assignTherbligsToSegments(studyId)
      if (error) {
        console.log('error', error)
        return
      }
    },

    async assignTherbligFromStepName(payload) {
      const { newStudyId: studyId } = useStationStore()
      const [error, data] = await ClassificationService.assignTherbligFromStepName(studyId, payload)
      if (error) {
        console.log('error', error)
        return []
      }
      return data?.therbligs || []
    },

    async getTherbligs() {
      const [error, data] = await ClassificationService.getTherbligs()
      if (error) {
        console.log('error:', error)
        return
      }
      const mappedData = data.map((el, index) => ({
        ...el,
        order: sortElements[el.name] !== undefined ? sortElements[el.name] : index + 1
      }));

      // Assign mapped data to this.therbligs
      this.therbligs = mappedData;
      this.actionNameToDescMap = data.reduce((res, el) => {
        res[el.name] = el.description ? el.description : el.name
        return res
      }, {})
    },

    resetTherbligsData() {
      this.therbligs = []
      this.actionNameToDescMap = {}
    }
  }
})
